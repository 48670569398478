<div class="service-panel-navgar-top"></div>
<nav class="navbar navbar-expand-lg navbar-service-panel">
  <a class="navbar-brand" routerLink="/">
    <span class="sign"></span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
          aria-expanded="false">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item" routerLink="/devices" routerLinkActive="active">
        <a class="nav-link">{{'Devices'|translate}}</a>
      </li>
      <li class="nav-item" routerLink="/incident/list" routerLinkActive="active">
        <a class="nav-link">{{'Incidents'|translate}}</a>
      </li>
      <li class="nav-item" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link">{{'Threads'|translate}}</a>
      </li>
      <li *ngIf="isContractedUser" class="nav-item" routerLink="/reports/files" routerLinkActive="active">
        <a class="nav-link">{{'Reports'|translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link">{{'Configuration'|translate}}</a>
      </li>
    </ul>
    <theme-logged-user></theme-logged-user>
  </div>
</nav>
