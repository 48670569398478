import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, ApiResponse } from '../_helpers';
import { IncidentEntity } from './entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { ScopeEnum } from '../core';

interface IncidentInfo {
  contractId: string;
  deviceId: string;
  incidentTypeId: string;
  incidentSourceId: string;
  description: string;
  scope: string;
}

@Injectable({
  providedIn: 'root',
})
export class IncidentService extends BaseService<IncidentEntity> {
  constructor(protected http: HttpClient) {
    super('incidents', IncidentEntity);
  }

  public saveWithAttachment(payload: IncidentInfo, attachments: File[]): Observable<IncidentEntity> {
    const formData: FormData = new FormData();
    attachments.forEach(a => formData.append('attachments', a));

    const fileMetadataBlob = new Blob([JSON.stringify(payload)], {
      type: 'application/json',
    });
    formData.append('json', fileMetadataBlob);
    return this.http
      .post<ApiResponse<IncidentEntity>>(this.url, formData)
      .pipe(map(data => plainToClass<IncidentEntity, object>(IncidentEntity, data)));
  }
}
