import { get } from 'lodash';
import { Expose, Type } from 'class-transformer';
import { BaseEntity } from '../../_helpers';
import { DeviceEntity } from './device.entity';
import { IncidentSource, IncidentTypeEntity, ScopeEnum, UserEntity } from '../../core';
import { Category } from '../../core/entity/category.entity';
import { AttachmentEntity } from 'src/app/core/entity/attachment.entity';
import {IncidentOriginEnum} from '../../core/enum/incident-origin.enum';

export class IncidentEntity extends BaseEntity {
  @Expose()
  @Type(() => DeviceEntity)
  public device: DeviceEntity;

  @Expose()
  public description: string;

  @Expose()
  @Type(() => AttachmentEntity)
  public attachments: AttachmentEntity[];

  @Expose()
  public scope: ScopeEnum;

  @Expose()
  public incidentOrigin: IncidentOriginEnum;

  @Expose()
  @Type(() => IncidentSource)
  public incidentSource: IncidentSource;

  @Expose()
  public reporter: UserEntity;

  @Expose()
  @Type(() => Date)
  public created: Date;

  @Expose()
  @Type(() => Date)
  public assigned: Date;

  @Expose()
  @Type(() => IncidentTypeEntity)
  public incidentType: IncidentTypeEntity;

  @Expose()
  public contractId: string;

  @Expose()
  public contractName: string;

  @Expose()
  @Type(() => Category)
  public category: Category;

  @Expose()
  @Type(() => Date)
  public reactionDueDate: Date;

  get location() {
    return get(this, 'device.locationName');
  }

  get city() {
    return get(this, 'device.city');
  }

  get cityLocation() {
    return [get(this, 'device.city'), get(this, 'device.locationName')].filter(Boolean).join(', ');
  }

  public isReactionDateEndangered(): Boolean {
    const timeLeft: number = this.reactionDueDate.getTime() - Date.now();
    return timeLeft <= 2 * 60 * 1000;
  }
}
