import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../../_helpers';

@Exclude()
export class ActivityConfigDetailEntity {

  @Expose()
  label: string;
}

@Exclude()
export class ActivityConfigEntity extends BaseEntity {

  @Expose()
  public name: string;

  @Expose()
  public activityType: string;
}
