import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

type FileDownloadParams = HttpParams | {
  [param: string]: string | string[];
};

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(protected http: HttpClient) {}

  public downloadFile(url: string, params?: FileDownloadParams) {
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
        params,
      })
      .pipe(tap(data => this.download(data)));
  }

  private download(resp: HttpResponse<Blob>) {
    const url = window.URL.createObjectURL(resp.body);
    const element = document.createElement('a');
    element.href = url;
    element.download = this.getFileNameFromHttpResponse(resp);
    document.body.appendChild(element);
    element.click();
    element.remove();
  }

  private getFileNameFromHttpResponse(httpResponse: HttpResponse<Blob>) {
    const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }
}
