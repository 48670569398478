import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CarEntity, WorkerEntity } from '../entity';
import { DictionaryEntity } from '../entity';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {
  public locations$: Observable<DictionaryEntity[]>;
  public incidentTypes$: Observable<DictionaryEntity[]>;
  public cars$: Observable<CarEntity[]>;
  public workers$: Observable<WorkerEntity[]>;
  public contracts$: Observable<DictionaryEntity[]>;
}
