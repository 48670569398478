import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {IncidentService} from './incident.service';
import {IncidentEntity} from './entity';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncidentEntityResolver implements Resolve<IncidentEntity> {
  constructor(private incidentService: IncidentService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<IncidentEntity> {
    return this.incidentService.findById(route.paramMap.get('incident-id'));
  }
}
