import { Component, OnInit, OnDestroy } from '@angular/core';
import { _t } from '../../_helpers';
import { ToastService } from '../../core/services';
import { IncidentService } from '../incident.service';
import { SortableSource } from '../../core/components/table/sortable-source';
import { IncidentEntity } from '../entity';
import { IncidentsStompService } from 'src/app/core/services/stomp/incidents-stomp.service';
import { merge, Subscription } from 'rxjs';

@Component({
  selector: 'app-incident-list',
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.scss'],
})
export class IncidentListComponent implements OnInit, OnDestroy {
  public sortableIncidents = new SortableSource<IncidentEntity>();
  public lastRefresh: Date;
  private filters = {};
  private incidents: IncidentEntity[] = [];
  private incidentChangesSubscription: Subscription;

  constructor(
    private toast: ToastService,
    private incidentsStompService: IncidentsStompService,
    private incidentService: IncidentService
  ) {
    this.sortableIncidents.setCustomSort('created', 'desc', (t1, t2) => t2.created.getTime() - t1.created.getTime());
    this.sortableIncidents.setCustomSort('created', 'asc', (t1, t2) => t1.created.getTime() - t2.created.getTime());
    this.sortableIncidents.setCustomSort(
      'reactionDueDate',
      'desc',
      (t1, t2) => t2.reactionDueDate.getTime() - t1.reactionDueDate.getTime()
    );
    this.sortableIncidents.setCustomSort(
      'reactionDueDate',
      'asc',
      (t1, t2) => t1.reactionDueDate.getTime() - t2.reactionDueDate.getTime()
    );
  }

  public async ngOnInit(): Promise<void> {
    this.listenToIncidentChanges();
    await this.loadIncidents();
  }

  ngOnDestroy() {
    if (!!this.incidentChangesSubscription) {
      this.incidentChangesSubscription.unsubscribe();
    }
  }

  public async doRefresh(): Promise<void> {
    await this.loadIncidents();
    this.toast.info(_t('Incidents refreshed!'));
  }

  public async doFilter(filterParam): Promise<void> {
    this.filters = {
      ...this.filters,
      ...filterParam,
    };
    return this.loadIncidents();
  }

  private async loadIncidents(): Promise<void> {
    const incidents = await this.incidentService
      .find({
        params: {
          ...this.filters,
        },
      })
      .toPromise();
    this.incidents = incidents || [];
    this.sortableIncidents.updateSource(this.incidents);
    this.sortableIncidents.sort('reactionDueDate', 'desc');
    this.lastRefresh = new Date();
  }

  private listenToIncidentChanges() {
    this.incidentChangesSubscription = merge(
      this.incidentsStompService.newIncident$,
      this.incidentsStompService.deleteIncident$
    ).subscribe(() => this.loadIncidents());
  }
}
