import {Exclude, Expose, Type} from 'class-transformer';
import {EntitySerialization} from '../../_helpers';

@Exclude()
export class Confirmation extends EntitySerialization {
  @Expose()
  @Type(() => Date)
  public dateTime: Date;

  @Expose()
  public userName: string;
}
