import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../components';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor(private readonly modalService: NgbModal) {}

  async confirm(title: string, desc: string, submitButtonText = 'Yes', dismissButtonText = 'No'): Promise<any> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      size: 'sm',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.description = desc;
    modalRef.componentInstance.submitButtonText = submitButtonText;
    modalRef.componentInstance.dismissButtonText = dismissButtonText;
    await modalRef.result;
  }
}
