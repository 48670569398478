import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { IncidentNotificationService } from 'src/app/incident/incident-notification.service';
import { ThreadNotificationService } from '../../thread/thread-notification.service';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService,
              private incidentNotificationService: IncidentNotificationService,
              private threadNotificationService: ThreadNotificationService) {
  }

  public async ngOnInit() {
    this.incidentNotificationService.close();
    this.threadNotificationService.close();
    return this.authService.logout();
  }
}
