import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {

  public title: string;
  public description: string;
  public submitButtonText = 'Submit';
  public dismissButtonText = 'Cancel';

  constructor(public activeModal: NgbActiveModal) { }
}
