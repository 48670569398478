import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../_helpers';
import { ActivityConfigEntity } from '../entity/activity-config.entity';
import { Observable } from 'rxjs';
import { defaultsDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends BaseService<ActivityConfigEntity> {
  constructor(protected http: HttpClient) {
    super('config/activities/idAndNames', ActivityConfigEntity);
  }

  findForDeviceTypeIds(ids: string[], options?: any): Observable<ActivityConfigEntity[]> {
    options = defaultsDeep({
      ...options
    }, {
      params: {
        deviceTypeIds: ids,
      }
    });
    return super.find(options);
  }
}
