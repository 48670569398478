<div class="row justify-content-center m-0">
  <div class="card col-8">
    <div class="card-header">
      <h3>{{'Edit incident'|translate}}</h3>
    </div>
    <div class="card-body">
      <form id="incidentForm" [formGroup]="incidentForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-row">
          <div class="form-group col-md-4">
            <select class="form-control large" formControlName="incidentTypeId">
              <option value="" disabled hidden>{{'Type'|translate}}</option>
              <option *ngFor="let type of apiConfig.incidentTypes$ | async" [value]="type.id">{{type.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-row">

          <div class="form-group col-md-6">
            <select class="form-control large" formControlName="scope">
              <option value="" disabled selected hidden>{{'Scope'|translate}}</option>
              <option *ngFor="let device of incidentScope | keys" [value]="device.value">{{device.key}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <textarea class="form-control" rows="6" placeholder="{{'Description'|translate}}"
                    formControlName="description"></textarea>
        </div>
      </form>
    </div>
    <div class="card-footer text-right">
      <button type="button" class="btn btn-outline-danger" [routerLink]="['/']">
        {{'Cancel'|translate}}
      </button>
      &nbsp;
      <button type="submit" form="incidentForm" [disabled]="!incidentForm.valid" class="btn btn-outline-success">
        {{'Submit'|translate}}
      </button>
    </div>
  </div>
</div>
