import {Expose, Type} from 'class-transformer';
import {BaseEntity} from '../../_helpers';

export class DeviceEntity extends BaseEntity {

@Expose()
public shortName: string;

@Expose()
public name: string;

@Expose()
public deviceTypeId: string;

@Expose()
public deviceTypeName: string;

@Expose()
public locationId: string;

@Expose()
public locationName: string;

}
