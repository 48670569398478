import {HttpParams} from '@angular/common/http';
import {ThreadStatusEnum} from '../../core/enum';

export class ThreadSearchInput {
  contractIds = '';
  severity = '';
  threadNumber = '';
  deviceTypeName = '';
  locationName = '';
  city = '';
  maintenanceCompanyName = '';
  incidentCreationDate: Date = null;
  threadCloseDueDate: Date = null;
  activeOnly = true;
  threadStatus = '';

  static convertObjectToParams(data: ThreadSearchInput) {
    let p = new HttpParams();
    p = this.appendThreadStatusParams(data, p);
    const isValuePresent = key => data[key];
    ['severity', 'threadNumber', 'deviceTypeName', 'locationName', 'city', 'maintenanceCompanyName', 'contractIds']
      .filter(isValuePresent)
      .forEach(key => {
          p = p.append(key, data[key]);
        }
      );
    ['incidentCreationDate', 'threadCloseDueDate']
      .filter(isValuePresent)
      .forEach(key => {
          p = p.append(key, new Date(data[key]).toISOString());
        }
      );
    return p;
  }
  private static appendThreadStatusParams(data: ThreadSearchInput, p: HttpParams) {
    if (data.threadStatus) {
      p = p.set('threadStatus', data.threadStatus);
    } else if (data.activeOnly) {
      Object.keys(ThreadStatusEnum)
        .filter(threadStatus => threadStatus !== ThreadStatusEnum.CLOSED)
        .forEach(threadStatus => p = p.append('threadStatus', threadStatus));
    }
    return p;
  }

  convertObjectToParams() {
    return ThreadSearchInput.convertObjectToParams(this);
  }
}
