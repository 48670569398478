import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../../_helpers';

@Exclude()
export class WorkerEntity extends BaseEntity {
  @Expose()
  public name: string;

  @Expose()
  public surname: string;

  public checked?: boolean; // only for activity-create.component.html view

  get fullName() {
    return this.name + ' ' + (this.surname || '');
  }
}
