import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../../_helpers';


@Exclude()
export class UserEntity extends BaseEntity {

  @Expose()
  public username: String;

}
