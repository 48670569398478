import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {StorageServiceModule} from 'ngx-webstorage-service';
import {ThemeModule} from '../layout/theme';
import {AuthHttpClient} from './auth-http-client';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthComponent} from './auth.component';
import {AuthGuard} from './auth.guard';
import {LoginComponent} from './login/login.component';
import {AuthInterceptor} from './auth.interceptor';
import {LogoutComponent} from './logout/logout.component';
import {ContractedUserGuard} from './contracted-user.guard';

export const AUTH_PROVIDERS = [
  AuthHttpClient,
  AuthGuard,
  ContractedUserGuard,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
];

const COMPONENTS = [
  LoginComponent,
  LogoutComponent,
  AuthComponent
];

@NgModule({
  imports: [
    HttpClientModule,
    StorageServiceModule,
    ThemeModule,
    AuthRoutingModule
  ],
  providers: [...AUTH_PROVIDERS],
  declarations: [...COMPONENTS]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        ...AUTH_PROVIDERS
      ]
    };
  }
}
