import { ApiConfigService } from '../../../../core/services';
import { Component, ViewEncapsulation } from '@angular/core';
import { DictionaryEntity } from '../../../../core/entity';
import { interval, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectionItem } from '../../dto/selection-item';
import { ThemeNavbarService } from '../../theme-navbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'theme-sub-navbar',
  templateUrl: './sub-navbar.component.html',
  styleUrls: ['./sub-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubNavbarComponent {

  constructor(
    private apiConfigService: ApiConfigService,
    public broadcastService: ThemeNavbarService,
    private translator: TranslateService,
  ) {
  }

  public now$ = interval(1000).pipe(map(() => new Date()));

  public contracts: SelectionItem[] = [];
  public selectedContracts: SelectionItem[] = [];
  public readonly dropdownSettings = {
    selectAllText: this.translator.instant('Select all'),
    unSelectAllText: this.translator.instant('Unselect all'),
  };

  private contractsSubscription: Subscription;

  private static getContractsFromSessionStorage() {
    return JSON.parse(window.sessionStorage
      .getItem('contracts'));
  }

  private static removeContractsFromSessionStorage() {
    window.sessionStorage.removeItem('contracts');
  }

  public ngOnInit() {
    this.contractsSubscription = this.apiConfigService.contracts$.subscribe((data) => this.setContracts(data));
  }

  public ngOnDestroy() {
    this.contractsSubscription?.unsubscribe();
    SubNavbarComponent.removeContractsFromSessionStorage();
  }

  public onSelectionChanged() {
    this.broadcastService.contractsSelection.next(this.selectedContracts);
    this.saveSelectedContractsInSessionStorage();
  }

  public onToggleSelectAll(event) {
    this.selectedContracts = event;
    this.onSelectionChanged();
  }

  private setContracts(contracts: DictionaryEntity[]) {
    const wasEmpty = this.contracts.length === 0;
    const areNoContractsInSessionStorage = window.sessionStorage.getItem('contracts') == null;
    if (contracts) {
      if (areNoContractsInSessionStorage) {
        this.contracts = contracts.map((contract) => new SelectionItem(contract.id, contract.name, false));
        this.selectedContracts = this.contracts;
        this.saveSelectedContractsInSessionStorage();
      } else {
        this.contracts = contracts.map((contract) => new SelectionItem(contract.id, contract.name, false));
        this.selectedContracts = SubNavbarComponent.getContractsFromSessionStorage()
          .map((contract) => new SelectionItem(contract.id, contract.text, false));
      }
    }
    if (wasEmpty) {
      this.onSelectionChanged();
    }
  }

  private saveSelectedContractsInSessionStorage() {
    window.sessionStorage.setItem('contracts', JSON.stringify(this.selectedContracts));
  }
}
