<div class="user-block-service-panel">
  <form class="form-inline">
    <div class="user-info">
      <span class="icon icon-user"></span>
      <span>{{authService.loggedUsername}}</span>
      <span class="user-actions">
        <button type="button" routerLink="/auth/logout" class="btn logout-button">{{'Logout'|translate}}</button>
      </span>
    </div>
  </form>
</div>
