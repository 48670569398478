import { Component, OnInit, OnDestroy } from '@angular/core';
import { IncidentProjectionService } from '../incident-projection.service';
import { Observable, Subscription, merge } from 'rxjs';
import { DictionaryEntity } from '../../core/entity';
import { ApiConfigService } from '../../core/services';
import { IncidentsStompService } from '../../core/services/stomp/incidents-stomp.service';
import { IncidentEntity } from '../entity';
import { Pageable } from '../../pageable/pageable';
import { filter } from 'rxjs/operators';
import {PageableParams} from '../../pageable/pageable.params';
import {SortDirection} from '../../pageable/sort.direction';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-incident-detailed-list',
  templateUrl: './incident-detailed-list.component.html',
  styleUrls: ['./incident-detailed-list.component.scss'],
})
export class IncidentDetailedListComponent implements OnInit, OnDestroy {
  contracts$: Observable<DictionaryEntity[]>;
  currentPage: Pageable<IncidentEntity>;
  contractId = '';
  pageableParams = this.createPageableParams('incidentCreationDate', SortDirection.DESC);

  private incidentChangesSubscription: Subscription;

  constructor(
    private incidentService: IncidentProjectionService,
    private apiConfigService: ApiConfigService,
    private stompService: IncidentsStompService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit() {
    this.contracts$ = this.apiConfigService.contracts$;
    this.restoreFilterFromUrl();
    this.fetchCurrentPage();

    this.listenToIncidentChanges();
  }

  ngOnDestroy() {
    if (!!this.incidentChangesSubscription) {
      this.incidentChangesSubscription.unsubscribe();
    }
  }

  private resetToFirstPage() {
    this.changePageIndex(0);
  }

  changePageIndex(pageIdx: number) {
    this.pageableParams.pageIdx = pageIdx;
    this.saveFiltersSortingAndPageToUrl();
    this.fetchCurrentPage();
  }

  fetchCurrentPage() {
    this.incidentService
      .findAll(this.pageableParams, this.contractId)
      .subscribe(page => (this.currentPage = page));
  }

  onContractSelect() {
    this.resetToFirstPage();
  }

  sortBy(sortField: string) {
    this.pageableParams.sortBy(sortField);
    this.resetToFirstPage();
  }

  private listenToIncidentChanges() {
    this.incidentChangesSubscription = merge(this.stompService.newIncident$, this.stompService.deleteIncident$)
      .pipe(filter(i => this.allContractsSelected() || i.contractId === this.contractId))
      .subscribe(() => this.fetchCurrentPage());
  }

  private allContractsSelected() {
    return this.contractId === '';
  }

  private saveFiltersSortingAndPageToUrl() {
    const params = {
      contract: this.contractId,
      page: this.pageableParams.pageIdx + 1,
      sort: this.pageableParams.sortField + ',' + this.pageableParams.sortDirection
    };
    this.router.navigate([], { queryParams: params, replaceUrl: true });
  }

  private restoreFilterFromUrl() {
    const contract = this.route.snapshot.queryParamMap.get('contract');
    if (contract) {
      this.contractId = contract;
    }
  }

  private createPageableParams(defaultSortField: string, defaultSortDirection: SortDirection): PageableParams {
    const savedData = this.route.snapshot.queryParamMap.get('sort');
    if (savedData) {
      const sort = savedData.split(',');
      if (sort.length === 2 && (sort[1] === SortDirection.ASC) || (sort[1] === SortDirection.DESC)) {
        const p = new PageableParams(sort[0], (sort[1] === SortDirection.ASC) ? SortDirection.ASC : SortDirection.DESC);
        p.pageIdx = +this.route.snapshot.queryParamMap.get('page') - 1;
        return p;
      } else {
        console.error('Unrecognized sorting order:', savedData);
      }
    }
    return new PageableParams(defaultSortField, defaultSortDirection);
  }
}
