import {ModuleWithProviders, NgModule} from '@angular/core';
import {CoreModule} from '../core';
import {ThemeModule} from '../layout/theme';
import {IncidentCreateComponent} from './incident-create/incident-create.component';
import {IncidentEditComponent} from './incident-edit/incident-edit.component';
import {IncidentListComponent} from './incident-list/incident-list.component';
import {IncidentRoutingModule} from './incident-routing.module';
import {IncidentDetailComponent} from './incident-detail/incident-detail.component';
import {IncidentEntityResolver} from './incident-entity.resolver';
import {IncidentThreadListModalComponent} from './incident-thread-list-modal/incident-thread-list-modal.component';
import {IncidentDetailedListComponent} from './incident-detailed-list/incident-detailed-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const COMPONENTS = [
  IncidentListComponent,
  IncidentCreateComponent,
  IncidentDetailComponent,
  IncidentThreadListModalComponent,
  IncidentEditComponent,
  IncidentDetailedListComponent,
];

const PROVIDERS = [
  IncidentEntityResolver,
];

@NgModule({
  imports: [
    ThemeModule,
    CoreModule,
    IncidentRoutingModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [...PROVIDERS],
  entryComponents: [IncidentThreadListModalComponent]
})
export class IncidentModule {
  static forRoot(): ModuleWithProviders<IncidentModule> {
    return {
      ngModule: IncidentModule,
      providers: [...PROVIDERS]
    };
  }
}
