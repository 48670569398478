import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(stringEnum: object, args: string[]): any {
    const keyValue = [];
    const keys = Object.keys(stringEnum);

    for (const k of keys) {
      keyValue.push({key: k, value: stringEnum[k]});
    }

    return keyValue;
  }
}
