import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { SelectionItem } from './dto/selection-item';

@Injectable({
  providedIn: 'root',
})
export class ThemeNavbarService {
  public readonly contractsSelection: ReplaySubject<SelectionItem[]> = new ReplaySubject(1);
}
