import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../../_helpers';
import {WorkerEntity} from '../entity';

@Injectable({
  providedIn: 'root'
})
export class WorkerService extends BaseService<WorkerEntity> {

  constructor(protected http: HttpClient) {
    super('config/workers', WorkerEntity);
  }
}
