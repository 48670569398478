import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { RxStomp } from '@stomp/rx-stomp';
import { AuthService } from '../../../auth/auth.service';
import { merge, Subject, Subscription } from 'rxjs';
import { IncidentEntity } from '../../../incident/entity';
import { getStompConfig } from './stomp.config';
import { deserialize } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class IncidentsStompService {
  private rxStomp = new RxStomp();

  private newIncidentsTopicPrefix = '/topic/new-incident';
  private newIncidentsSubject = new Subject<IncidentEntity>();
  private _newIncident$ = this.newIncidentsSubject.asObservable();
  private newIncidentsSubscription: Subscription;

  private deleteIncidentsTopicPrefix = '/topic/delete-incident';
  private deleteIncidentsSubject = new Subject<IncidentEntity>();
  private _deleteIncident$ = this.deleteIncidentsSubject.asObservable();
  private deleteIncidentsSubscription: Subscription;

  constructor(private authService: AuthService) {
    this.authService.loggedOut$.subscribe(() => {
      this.disconnect();
    });
  }

  get newIncident$() {
    this.ensureNewIncidentsWatcher();
    return this._newIncident$;
  }

  get deleteIncident$() {
    this.ensureDeleteIncidentsWatcher();
    return this._deleteIncident$;
  }

  private ensureNewIncidentsWatcher() {
    if (this.newIncidentsSubscription && !this.newIncidentsSubscription.closed) {
      return;
    }

    this.newIncidentsSubscription = this.initIncidentWatcher(this.newIncidentsTopicPrefix, this.newIncidentsSubject);
  }

  private ensureDeleteIncidentsWatcher() {
    if (this.deleteIncidentsSubscription && !this.deleteIncidentsSubscription.closed) {
      return;
    }

    this.deleteIncidentsSubscription = this.initIncidentWatcher(
      this.deleteIncidentsTopicPrefix,
      this.deleteIncidentsSubject
    );
  }

  private initIncidentWatcher(topicPrefix: string, incidentSubject: Subject<IncidentEntity>) {
    this.ensureStompConnected();

    const token = this.authService.token;
    const contracts = this.authService.userContracts;
    if (contracts) {
      const topicWatchers = contracts.map(contractId =>
        this.rxStomp.watch(`${topicPrefix}/${contractId}`, {
          token,
        })
      );

      return this.rxStomp.connected$
        .pipe(
          switchMap(() => merge(...topicWatchers)),
          map(message => {
            return deserialize<IncidentEntity>(IncidentEntity, message.body);
          })
        )
        .subscribe(incidentSubject);
    }
    return null;
  }

  private ensureStompConnected() {
    if (this.rxStomp.active) {
      return;
    }

    const config = getStompConfig(this.authService.token);
    this.rxStomp.configure(config);
    this.rxStomp.activate();
  }

  private disconnect() {
    if (this.newIncidentsSubscription) {
      this.newIncidentsSubscription.unsubscribe();
    }
    if (this.deleteIncidentsSubscription) {
      this.deleteIncidentsSubscription.unsubscribe();
    }
    this.rxStomp.deactivate();
  }
}
