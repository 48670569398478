import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { get } from 'lodash';
import { map } from 'rxjs/operators';
import { BaseService } from '../_helpers';
import { ActivityEntity, NoteEntity } from '../core/entity';
import { IncidentEntity } from '../incident/entity';
import { ThreadEntity } from './entity';
import { ChangeSlaInput } from './dto/change-sla-input.dto';
import { Pageable } from '../pageable/pageable';
import { PageableParams } from '../pageable/pageable.params';
import { ThreadDto } from './dto/thread.dto';
import { ReasonInput } from './dto/reason-input.dto';

@Injectable({
  providedIn: 'root',
})
export class ThreadService extends BaseService<ThreadEntity> {
  constructor(protected http: HttpClient) {
    super('threads', ThreadEntity);
  }

  public findArchives(thread: ThreadEntity, options?: any): Observable<ThreadEntity[]> {
    return this.http
      .get(`${this.url}/${thread.id}/relation/devices`, options)
      .pipe(map((data) => plainToClass<ThreadEntity, any>(ThreadEntity, get(data, '_embedded.threads'))));
  }

  public saveNote(thread: ThreadEntity, model: NoteEntity, options?: any): Observable<NoteEntity> {
    const method = model.id ? 'put' : 'post';
    const url = model.id ? `${this.url}/${thread.id}/notes/${model.id}` : `${this.url}/${thread.id}/notes`;
    return this.http
      .request(method, url, {
        body: model,
        ...options,
      })
      .pipe(map((data) => plainToClass(NoteEntity, data)));
  }

  public addIncident(thread: ThreadEntity, model: IncidentEntity, options?: any): Observable<any> {
    return this.http.post(`${this.url}/${thread.id}/incidents`, {
      incidentId: model.id,
      ...options,
    });
  }

  public saveActivity(thread: ThreadEntity, model: Partial<ActivityEntity>, options?: any): Observable<ActivityEntity> {
    const method = model.id ? 'put' : 'post';
    const url = model.id ? `${this.url}/${thread.id}/activities/${model.id}` : `${this.url}/${thread.id}/activities`;
    return this.http
      .request(method, url, {
        body: {
          configActivityId: model.configActivityId,
          description: model.description,
          activityDateTime: model.activityDateTime.toISOString(),
          cars: model.cars,
          workers: model.workers,
        },
        ...options,
      })
      .pipe(map((data) => plainToClass(ActivityEntity, data)));
  }

  public deleteActivity(threadId: string, activityId: string): Observable<void> {
    return this.http.delete(`${this.url}/${threadId}/activities/${activityId}`).pipe(map(() => {}));
  }

  public closeThread(threadId: string, description: string, incidentTypeSummaryId: string): Observable<ThreadEntity> {
    return this.http
      .post(`${this.url}/${threadId}/close`, {
        description: description,
        incidentTypeSummaryId: incidentTypeSummaryId,
      })
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  public assignThread(
    thread: ThreadEntity,
    maintenanceCompanyId: string,
    description: string
  ): Observable<ThreadEntity> {
    return this.http
      .post(`${this.url}/${thread.id}/assign`, {
        maintenanceCompanyId: maintenanceCompanyId,
        description: description,
      })
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  public rejectThread(thread: ThreadEntity, reason: string): Observable<ThreadEntity> {
    return this.http
      .post(`${this.url}/${thread.id}/reject`, {
        reason: reason,
      })
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  public acceptThread(thread: ThreadEntity, reason: string): Observable<ThreadEntity> {
    return this.http
      .post(`${this.url}/${thread.id}/accept`, {
        reason: reason,
      })
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  public finishThread(thread: ThreadEntity, reason: string): Observable<ThreadEntity> {
    return this.http
      .post(`${this.url}/${thread.id}/finish`, {
        reason: reason,
      })
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  public returnThread(thread: ThreadEntity, reason: string): Observable<ThreadEntity> {
      return this.http
        .post(`${this.url}/${thread.id}/return`, {
          reason: reason,
        })
        .pipe(map((response) => plainToClass(ThreadEntity, response)));
    }

  public reopenThread(thread: ThreadEntity, reason: string): Observable<ThreadEntity> {
    return this.http
      .post(`${this.url}/${thread.id}/reopen`, {
        reason: reason,
      })
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  public getFile(thread: ThreadEntity, url: string) {
    return this.http.get(`${this.url}/${thread.id}/docs/${url}`, {
      responseType: 'arraybuffer',
    });
  }

  public searchBy(pageAndSortParams: PageableParams, httpParams?: HttpParams): Observable<Pageable<ThreadDto>> {
    for (const [key, value] of Object.entries(pageAndSortParams.asHttpParams())) {
      httpParams = httpParams.append(key, value);
    }
    return this.http
      .get<Pageable<ThreadDto>>(`${this.url}/search`, { params: httpParams })
      .pipe(map((page) => this.convertContent(page, ThreadDto)));
  }

  private convertContent<T>(page, dtoClass) {
    page.content = plainToClass(dtoClass, page.content);
    return page;
  }

  public update(threadId: string, content: any): Observable<ThreadEntity> {
    return this.http
      .put(`${this.url}/${threadId}`, content)
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  changeSla(threadId: string, content: ChangeSlaInput) {
    return this.http
      .post(`${this.url}/${threadId}/sla`, content)
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  pauseThread(threadId: string, content: ReasonInput) {
    return this.http
      .post(`${this.url}/${threadId}/pause`, content)
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  resumeThread(threadId: string, content: ReasonInput) {
    return this.http
      .post(`${this.url}/${threadId}/resume`, content)
      .pipe(map((response) => plainToClass(ThreadEntity, response)));
  }

  acknowledgeDeadline(threadId: string) {
    return this.http.put(`${this.url}/${threadId}/deadlineAcknowledgement`, {});
  }

  searchByDeviceIdIn(thread: ThreadEntity, httpParams: HttpParams) {
    return this.http
      .get(`${this.url}/search/${thread.id}/device`, { params: httpParams })
      .pipe(
        map((data) =>
          plainToClass<ThreadEntity, any>(ThreadEntity, get(data, `_embedded.${this.path.split('/').pop()}`))
        )
      );
  }
}
