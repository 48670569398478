import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {LocationEntity} from '../../entity';
import {LocationDictionaryService} from '../../services';

@Component({
  selector: 'app-locations',
  template: `
    <form #f="ngForm" (submit)="onSelect()" novalidate>
      <div class="form-group">
        <select class="form-control" (change)="onSelect()" [compareWith]="myCompare" name="locations" [(ngModel)]="selectedLocation">
          <option [ngValue]="selectedLocation" disabled translate>Localization</option>
          <option *ngFor="let location of locations$ | async" [ngValue]="location" [selected]="location.id === selectedLocation?.id">
            {{location.name.fullName}}
          </option>
        </select>
      </div>
    </form>
  `
})
export class LocationsComponent implements OnInit {
  public locations$: Observable<LocationEntity[]>;
  public selectedLocation = new LocationEntity();

  @Output()
  public selected = new EventEmitter<LocationEntity>();

  constructor() {
  }

  ngOnInit() {
  }

  public onSelect() {
    this.selected.emit(this.selectedLocation);
  }

  public myCompare(c1: LocationEntity, c2: LocationEntity) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
