<div class="row justify-content-center m-0">
  <div class="card col-8">
    <div class="card-header">
      <h3>{{'New thread'|translate}}</h3>
    </div>
    <div class="card-body">
      <form [formGroup]="threadForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-row">
          <div class="form-group col-md-6">
            <input type="text" class="form-control" formControlName="type">
          </div>
          <div class="col-md-6">
            <app-locations></app-locations>
          </div>
        </div>
        <div class="form-group">
          <textarea class="form-control" rows="6" placeholder="{{'Description'|translate}}" formControlName="description"></textarea>
        </div>
        <button type="submit" [disabled]="!threadForm.valid" class="btn btn-outline-success">Submit</button>
        <button type="button" class="btn btn-outline-danger" [routerLink]="['/incident', incident.id]">Cancel</button>
      </form>
    </div>
  </div>
</div>
