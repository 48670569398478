import {Component} from '@angular/core';
import {AuthService} from '../../../../auth/auth.service';

@Component({
  selector: 'theme-logged-user',
  templateUrl: './logged-user.component.html',
  styleUrls: ['./logged-user.component.scss']
})
export class LoggedUserComponent {

  constructor(public authService: AuthService) {
  }
}
