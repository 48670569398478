<div class="row mr-0 ml-0">
  <div class="col-12 p-0">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'Threads' | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="inset grow vscroll" themeSimplebar>
          <table class="table table-bordered table-hover mb-0">
            <thead>
              <tr class="filters" [formGroup]="searchForm">
                <th class="filter active-col">
                  <div class="form-group">
                    <div class="form-check">
                      <label class="form-check-label" for="filter_active">
                        <input
                          formControlName="activeOnly"
                          type="checkbox"
                          checked
                          class="form-check-input"
                          id="filter_active"
                          name="active"
                        />
                        <span class="check-mark"></span>
                      </label>
                    </div>
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <select formControlName="severity" class="form-control">
                      <option value="">{{ '*' }}</option>
                      <option *ngFor="let item of severity | keys" [value]="item.value">{{
                        'SEVERITY.' + item.value | translate
                      }}</option>
                    </select>
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <input
                      formControlName="threadNumber"
                      type="text"
                      class="form-control"
                      placeholder="{{ 'Number' | translate }}"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <input
                      formControlName="deviceTypeName"
                      type="text"
                      class="form-control"
                      placeholder="{{ 'Device type' | translate }}"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <input
                      formControlName="locationName"
                      type="text"
                      class="form-control"
                      placeholder="{{ 'Localization' | translate }}"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <input
                      formControlName="city"
                      type="text"
                      class="form-control"
                      placeholder="{{ 'City' | translate }}"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <input
                      formControlName="maintenanceCompanyName"
                      type="text"
                      class="form-control"
                      placeholder="{{ 'Operator' | translate }}"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <select formControlName="threadStatus" class="form-control">
                      <option value="">{{ '*' }}</option>
                      <option *ngFor="let item of threadStatus | keys" [value]="item.value">{{
                        'THREAD_STATUS.' + item.value | translate
                      }}</option>
                    </select>
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <input
                      formControlName="incidentCreationDate"
                      type="date"
                      class="form-control"
                      placeholder="{{ 'Date' | translate }}"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="form-group">
                    <input
                      formControlName="threadCloseDueDate"
                      type="date"
                      class="form-control"
                      placeholder="{{ 'Term' | translate }}"
                    />
                  </div>
                </th>
                <th class="filter empty-cell time-left-col">
                  <div class="form-group"></div>
                </th>
              </tr>
              <tr>
                <th>{{ 'Active' | translate }}</th>
                <th>
                  {{ 'Severity' | translate }}
                  <a (click)="sortBy('severity')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th>
                  {{ 'Number' | translate }}
                  <a (click)="sortBy('threadNumber')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th>
                  {{ 'Device type' | translate }}
                  <a (click)="sortBy('deviceTypeName')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th>
                  {{ 'Localization' | translate }}
                  <a (click)="sortBy('locationName')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th>
                  {{ 'City' | translate }}
                  <a (click)="sortBy('city')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th>
                  {{ 'Operator' | translate }}
                  <a (click)="sortBy('maintenanceCompanyName')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th>
                  {{ 'Status' | translate }}
                  <a (click)="sortBy('threadStatus')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th>
                  {{ 'Date' | translate }}
                  <a (click)="sortBy('incidentCreationDate')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th>
                  {{ 'Term' | translate }}
                  <a (click)="sortBy('threadCloseDueDate')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
                <th class="time-left-col">
                  {{ 'Time' | translate }}
                  <a (click)="sortBy('threadCloseDueDate')"><img src="./assets/images/sort.png" alt=""/></a>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="currentPage?.content?.length; else loading">
              <tr
                [title]="thread.description"
                *ngFor="let thread of currentPage?.content"
                [routerLink]="['/thread/', thread.id, 'metric']"
                class="thread-details"
              >
                <td *ngIf="thread.isActive">{{ 'Yes' | translate }}</td>
                <td *ngIf="!thread.isActive">{{ 'No' | translate }}</td>
                <td>
                  <span class="severity" [ngClass]="thread.severity?.toLowerCase()"></span>
                  {{ thread.incidentName }}
                </td>
                <td>{{ thread.threadNumber }}</td>
                <td>{{ thread.deviceTypeName }}</td>
                <td>{{ thread.locationName }}</td>
                <td>{{ thread.city }}</td>
                <td>{{ thread.maintenanceCompanyName }}</td>
                <td>{{ 'THREAD_STATUS.' + thread.threadStatus | translate }}</td>
                <td>{{ thread.incidentCreationDate | date: 'short' }}</td>
                <td>{{ thread.threadCloseDueDate | date: 'short' }}</td>
                <td
                  *ngIf="thread.isActive"
                  [class.sla-deadline-warning]="thread.isSlaEndangered()"
                  class="time-left-col"
                >
                  {{ thread.threadCloseDueDate | timeLeft | async }}
                </td>
                <td *ngIf="!thread.isActive">---</td>
              </tr>
            </tbody>
            <ng-template #loading>
              <tbody>
                <tr *ngIf="displayLoader">
                  <td colspan="10" class="loader">
                    <app-loader></app-loader>
                  </td>
                </tr>
                <tr *ngIf="!displayLoader">
                  <td colspan="10" class="text-center">{{ 'There are no threads' | translate }}</td>
                </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
        <div *ngIf="displayPaginator" class="d-flex justify-content-end">
          <app-paginator
            [pagesCount]="currentPage?.totalPages"
            [pageIndex]="pageableParams.pageIdx"
            (pageIndexChange)="onChangePageIndex($event)"
          >
          </app-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
