import { Injectable } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ToastService } from '../core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IncidentsStompService } from '../core/services/stomp/incidents-stomp.service';
import { IncidentService } from './incident.service';
import { IncidentEntity } from './entity';

@Injectable({
  providedIn: 'root',
})
export class IncidentNotificationService {
  private newIncidentSubscription: Subscription = null;
  private endangeredIncidentSubscription: Subscription = null;
  private deletedIncidentSubscription: Subscription = null;
  private incidents: IncidentEntity[] = [];
  private audioNotifiedIncidents: IncidentEntity[] = [];

  constructor(
    private authService: AuthService,
    private toast: ToastService,
    private router: Router,
    private translateService: TranslateService,
    private stompService: IncidentsStompService,
    private incidentService: IncidentService
  ) {}

  initSocket() {
    const audio = this.initAudio('/assets/audio/notification.mp3');
    this.newIncidentSubscription = this.stompService.newIncident$.subscribe((incident) => {
      this.incidents.push(incident);
      if (!incident.reporter || incident.reporter.id !== this.authService.getUserId()) {
        const location = this.translateService.instant('Location:');
        this.playAudioIfPossible(audio);
        this.toast.info(
          `${location} ${incident.location}`,
          this.translateService.instant('New Incident'),
          {
            positionClass: 'toast-bottom-right',
            disableTimeOut: true,
            closeButton: true,
          },
          () => this.router.navigate([`incident/${incident.id}`])
        );
      }
    });
    this.deletedIncidentSubscription = this.stompService.deleteIncident$.subscribe((incident) => {
      const incidentIndex = this.incidents.indexOf(incident);
      this.incidents.splice(incidentIndex, 1);
    });
  }

  audioInterval() {
    const audio = this.initAudio('/assets/audio/alert.wav');
    this.incidentService.find().subscribe((incidents) => {
      if (incidents) {
        this.incidents = incidents;
      }
      this.addAllEndangeredIncidentsToAudioNotified();
    });
    this.endangeredIncidentSubscription = interval(5000).subscribe(() => this.checkForNewEndangeredIncidents(audio));
  }

  private addAllEndangeredIncidentsToAudioNotified() {
    this.audioNotifiedIncidents = this.incidents.filter((i) => i.isReactionDateEndangered());
  }

  private checkForNewEndangeredIncidents(audio: HTMLAudioElement) {
    const newEndangeredIncidents = this.incidents.filter((i) => i.isReactionDateEndangered());
    newEndangeredIncidents
      .filter((endangeredIncident) => !this.audioNotifiedIncidents.includes(endangeredIncident))
      .forEach((incidentToPlay) => {
        this.audioNotifiedIncidents.push(incidentToPlay);
        this.playAudioIfPossible(audio);
      });
  }

  close() {
    if (this.newIncidentSubscription) {
      this.newIncidentSubscription.unsubscribe();
    }
    if (this.endangeredIncidentSubscription) {
      this.endangeredIncidentSubscription.unsubscribe();
    }
    if (this.deletedIncidentSubscription) {
      this.deletedIncidentSubscription.unsubscribe();
    }
  }

  private initAudio(path: string) {
    const audio = new Audio();
    audio.src = path;
    audio.load();
    return audio;
  }

  private playAudioIfPossible(audio: HTMLAudioElement) {
    audio
      .play()
      .catch((e) =>
        console.warn(
          `Playing notification audio was not possible because user didn't interact with the web page yet`,
          e
        )
      );
  }
}
