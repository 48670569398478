export class SortableSource<T> {
  public data: ReadonlyArray<T> = [];
  private lastSortField: keyof T;
  private lastSortDirection: 'asc' | 'desc';
  private customSortFunctions: {[key: string]: (a: T, b: T) => number} = {};

  constructor(source?: T[]) {
    if (source) {
      this.updateSource(source);
    }
  }

  public updateSource(source: T[]) {
    this.data = source.slice();
    if (this.lastSortField && this.lastSortDirection) {
      this.sort(this.lastSortField, this.lastSortDirection);
    }
  }

  public setCustomSort(field: keyof T, direction: 'asc' | 'desc', func: (a: T, b: T) => number): void {
    this.customSortFunctions[field + direction] = func;
  }

  public sort(field: keyof T, direction: 'asc' | 'desc'): void {
    const customSortFunction = this.customSortFunctions[field + direction];
    if (customSortFunction) {
      this.data = this.data.slice().sort((a, b) => customSortFunction(a, b));
    } else if (direction === 'desc') {
      this.data = this.data.slice().sort((a, b) => b[field].toString().localeCompare(a[field].toString()));
    } else {
      this.data = this.data.slice().sort((a, b) => a[field].toString().localeCompare(b[field].toString()));
    }
    this.lastSortField = field;
    this.lastSortDirection = direction;
  }
}
