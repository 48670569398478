import {Expose, Type} from 'class-transformer';
import {BaseEntity} from '../../_helpers';

export class NoteEntity extends BaseEntity {

  @Expose()
  public username: string;

  @Expose()
  public subject: string;

  @Expose()
  public text: string;

  @Expose()
  @Type(() => Date)
  public created: Date;
}
