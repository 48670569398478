import {BaseService} from '../../_helpers';
import {HttpClient} from '@angular/common/http';
import {DictionaryEntity} from '../entity/dictionaryEntity';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IncidentSourceDictionaryService extends BaseService<DictionaryEntity> {
  constructor(protected http: HttpClient) {
    super('config/incidentSources/idAndNames', DictionaryEntity);
  }
}
