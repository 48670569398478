import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { PageableParams } from '../pageable/pageable.params';
import { Router } from '@angular/router';
import { SortDirection } from '../pageable/sort.direction';
import { ThreadDto } from './dto/thread.dto';
import { ThreadService } from './thread.service';
import { ThreadStatusEnum } from '../core/enum';
import { ToastService } from '../core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeNavbarService } from '../layout/theme/theme-navbar.service';
import { AuthService } from '../auth/auth.service';

const POLLING_PERIOD_MS = 300000;
const INFINITE_PAGE_SIZE = 999999999;

@Injectable({
  providedIn: 'root',
})
export class ThreadNotificationService {

  constructor(
    private toaster: ToastService,
    private router: Router,
    private translator: TranslateService,
    private threadService: ThreadService,
    private navbar: ThemeNavbarService,
    private authService: AuthService,
  ) {
  }

  private readonly warningSound = new Audio('/assets/audio/alert.wav');
  private searchParams = this.createSearchParams();
  private updatedSearchParams;
  private readonly pageableParams = new PageableParams('severity', SortDirection.ASC, INFINITE_PAGE_SIZE);
  private contractsSubscription: Subscription = null;
  private selectedContracts = [];
  private timerSubscription: Subscription = null;
  private fetchSubscription: Subscription = null;
  private toastsDisplayed: Set<String> = new Set();

  private readonly escapeHTML = s => s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

  initListeners() {
    this.timerSubscription = interval(POLLING_PERIOD_MS).subscribe(() => this.scanThreads());
    this.contractsSubscription = this.navbar.contractsSelection.subscribe(selected => this.onContractsChanged(selected));
  }

  ngOnDestroy() {
    this.close();
  }

  close() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
    if (this.contractsSubscription) {
      this.contractsSubscription.unsubscribe();
    }
  }

  private createSearchParams(): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(ThreadStatusEnum)
      .filter(threadStatus => threadStatus !== ThreadStatusEnum.CLOSED)
      .forEach(threadStatus => httpParams = httpParams.append('threadStatus', threadStatus));
    return httpParams;
  }

  private onContractsChanged(selection: Object[]) {
    this.selectedContracts = selection;
  }

  private scanThreads() {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
    const selectedContractIds = this.selectedContracts.map(contract => contract.id);
    const userAvailableContractIds = this.authService.userContracts;
    this.updatedSearchParams = this.searchParams;
    if (userAvailableContractIds) {
      const notSelectedContractIds = userAvailableContractIds.filter(x => !selectedContractIds.includes(x));
      if (notSelectedContractIds.length !== 0) {
        notSelectedContractIds.forEach(contractId => {
          this.updatedSearchParams = this.updatedSearchParams.append('contractId', contractId);
        });
        this.fetchSubscription = this.threadService
          .searchBy(this.pageableParams, this.updatedSearchParams)
          .subscribe(data => this.processThreads(data.content));
      }
    }
  }

  private processThreads(threads: ThreadDto[]) {
    let noWarnings = true;
    for (const thread of threads) {
      if (!this.toastsDisplayed.has(thread.id) && thread.isSlaEndangered()) {
        this.toastsDisplayed.add(thread.id);
        this.toaster.warning(
          this.escapeHTML(thread.description) + '<br>' + this.escapeHTML(`${thread.city}, ${thread.locationName}`),
          this.translator.instant('Handling time is running out'),
          {
            positionClass: 'toast-bottom-right',
            enableHtml: true,
            disableTimeOut: true,
            closeButton: true,
          },
          () => this.router.navigate(['thread', thread.id, 'metric']),
          () => this.toastsDisplayed.delete(thread.id),
        );
        if (noWarnings) {
          noWarnings = false;
          this.warningSound.play().catch(e => console.warn('Cannot play audio:', e));
        }
      }
    }
  }
}
