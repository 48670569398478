<div class="modal-header">
  <h4 class="modal-title">{{'Thread List'|translate}}</h4>
  <button type="button" class="close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="inset">
    <div class="inset grow vscroll" themeSimplebar>
      <table class="table table-bordered table-hover mb-0">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th>{{'Active'|translate}}</th>
          <th>{{'Severity'|translate}}</th>
          <th>{{'Number'|translate}}</th>
          <th>{{'Device type'|translate}}</th>
          <th>{{'Localization'|translate}}</th>
          <th>{{'Operator'|translate}}</th>
          <th>{{'Status'|translate}}</th>
          <th>{{'Date'|translate}}</th>
          <th>{{'Term'|translate}}</th>
          <th>{{'Time'|translate}}</th>
        </tr>
        </thead>
        <tbody *ngIf="currentPage?.totalPages">
        <tr *ngFor="let thread of currentPage.content" (click)="selectedThread=thread">
          <td scope="row">
            <div class="form-check p-0 m-0">
              <label class="form-check-label" [for]="thread.id">
                <input type="radio" [id]="thread.id" class="form-check-input" name="thread" [value]="thread"
                       [(ngModel)]="selectedThread">
                <span class="check-mark rounded-circle"></span>
              </label>
            </div>
          </td>
          <td *ngIf="thread.isActive">{{'Yes' | translate}}</td>
          <td *ngIf="!thread.isActive">{{'No' | translate}}</td>
          <td>
            <span class="severity" [ngClass]="thread.severity.toLowerCase()"></span>
            {{thread.incidentName}}
          </td>
          <td>{{thread.threadNumber}}</td>
          <td>{{thread.deviceTypeName }}</td>
          <td>{{thread.locationName}}</td>
          <td>{{thread.maintenanceCompanyName}}</td>
          <td>{{'THREAD_STATUS.' + thread.threadStatus | translate}}</td>
          <td>{{thread.incidentCreationDate | date: 'short' }}</td>
          <td>{{thread.threadCloseDueDate | date: 'short' }}</td>
          <td *ngIf="thread.isActive" [class.sla-deadline-warning]="thread.isSlaEndangered()">{{thread.threadCloseDueDate | timeLeft | async}}</td>
          <td *ngIf="!thread.isActive">---</td>
        </tr>
        </tbody>
        <tbody *ngIf="!currentPage?.totalPages">
        <tr class="text-center">
          <td colspan="11">{{'There are no threads'|translate}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="activeModal.close()">
    {{'Cancel'|translate}}
  </button>
  &nbsp;&nbsp;
  <button type="button" class="btn btn-outline-success" [disabled]="!selectedThread"
          (click)="activeModal.close(selectedThread)">
    {{'Save'|translate}}
  </button>
</div>
