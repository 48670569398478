import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IncidentTypesDictionaryService, ToastService } from '../../core/services';
import { DeviceEntity, IncidentEntity } from '../entity';
import { IncidentService } from '../incident.service';
import { DeviceDictionaryService } from '../../core/services/device-dictionary.service';
import { IncidentSourceDictionaryService } from '../../core/services/source-dictuionary.service';
import { ContractDictionaryService } from '../../core/services/contract-dictionary.service';
import { _t } from '../../_helpers';
import { DictionaryEntity } from '../../core/entity';
import { AutocompleteOption } from '../../core/components/autocomplete/autocomplete.component';
import { ScopeEnum } from '../../core/enum';

@Component({
  selector: 'app-incident-create',
  templateUrl: './incident-create.component.html',
  styleUrls: ['./incident-create.component.scss'],
})
export class IncidentCreateComponent implements OnInit {
  public incident: IncidentEntity;
  public incidentForm: FormGroup;

  public contracts: DictionaryEntity[] = [];
  public incidentSources: DictionaryEntity[] = [];
  public incidentTypes: DictionaryEntity[] = [];
  devices: AutocompleteOption[] = [];
  public attachments: File[] = [];
  public showFormErrors = false;
  creationInProgress = false;
  private _selectedDevice: DeviceEntity;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private toast: ToastService,
    private incidentService: IncidentService,
    private contractService: ContractDictionaryService,
    private deviceDictionaryService: DeviceDictionaryService,
    private incidentTypesDictionaryService: IncidentTypesDictionaryService,
    private incidentSourceDictionaryService: IncidentSourceDictionaryService,
  ) {
    this.incidentForm = fb.group({
      contractId: ['', Validators.required],
      deviceId: ['', Validators.required],
      incidentTypeId: ['', Validators.required],
      incidentSourceId: ['', Validators.required],
      description: ['', Validators.required],
      scope: [ScopeEnum.EXTERNAL],
    });
  }

  public ngOnInit(): void {
    this.getContracts();
  }

  public async onContractChange(contractId: string) {
    this.incidentForm.patchValue({contractId: contractId});
    this.resetSelectsOnContractChange();
    this.incidentSources = await this.incidentSourceDictionaryService.find({params: {contractId: contractId}}).toPromise();

    if (this.incidentSources.length === 1) {
      this.incidentForm.patchValue({incidentSourceId: this.incidentSources[0].id});
    }

    const devices = await this.deviceDictionaryService.find({
      params: {
        contractId: contractId
      }
    }).toPromise();
    this.devices = devices.map(device => ({value: device, name: device.name}));
  }

  get selectedDevice() {
    return this._selectedDevice;
  }

  set selectedDevice(device: DeviceEntity) {
    if (device) {
      this._selectedDevice = device;
      this.incidentForm.patchValue({deviceId: device.id});
      this.fetchIncidentTypes(device);
      this.incidentForm.patchValue({incidentTypeId: ''});
    }
  }

  addAttachments(files: FileList) {
    this.attachments.push.apply(this.attachments, files);
  }

  deleteAttachment(index: number) {
    this.attachments.splice(index, 1);
  }

  private async fetchIncidentTypes(device: DeviceEntity) {
    const configDevice = await this.deviceDictionaryService.findById(device.id).toPromise();
    this.incidentTypes = await this.incidentTypesDictionaryService.find({
      params: {
        deviceTypeId: configDevice.deviceTypeId
      }
    }).toPromise();
    if (this.incidentTypes.length === 1) {
      this.incidentForm.patchValue({incidentTypeId: this.incidentTypes[0].id});
    }
  }

  private resetSelectsOnContractChange() {
    this.incidentForm.patchValue({deviceId: ''});
    this.incidentForm.patchValue({incidentSourceId: ''});
    this.incidentForm.patchValue({incidentTypeId: ''});
    this.incidentForm.patchValue({attachments: ''});
  }

  public async onSubmit(): Promise<void> {
    if (this.incidentForm.invalid) {
      this.showFormErrors = true;
      return;
    }

    this.showFormErrors = false;
    this.creationInProgress = true;
    const { contractId, categoryId, deviceTypeId, locationId, ...payload } = this.incidentForm.value;

    try {
      const incident = await this.incidentService.saveWithAttachment(payload, this.attachments).toPromise();
      await this.router.navigate(['/incident', incident.id]);
      this.toast.success('Incident successfully created', 'Success!');
    } finally {
      this.creationInProgress = false;
    }
  }

  public get errorControls(): string[] {
    const invalidControlsNames = [];
    for (const name in this.incidentForm.controls) {
      if (this.incidentForm.controls[name].invalid) {
        invalidControlsNames.push(name);
      }
    }
    return invalidControlsNames;
  }

  private async getContracts(): Promise<void> {
    this.contracts = await this.contractService.find().toPromise();
    if (this.contracts.length === 1) {
      this.onContractChange(this.contracts[0].id);
    }
  }
}
