import {Injectable} from '@angular/core';
import {BaseService} from '../../_helpers';
import {HttpClient} from '@angular/common/http';
import {DictionaryEntity} from '../entity';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceCompanyDictionaryService extends BaseService<DictionaryEntity> {
  constructor(protected http: HttpClient) {
    super('config/maintenanceCompanies/idAndNames', DictionaryEntity);
  }
}
