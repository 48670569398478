<div class="row justify-content-center m-0">
  <div class="card col-8">
    <div class="card-header">
      <div class="row justify-content-between">
        <div class="col-8">
          <h3>{{ 'Incident details' | translate }}</h3>
        </div>
        <div class="col-2 text-right">
          <button class="btn btn-block d-none btn-outline-success" [routerLink]="['./edit']">
            {{ 'Modify' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="inset">
        <table class="table table-detail table-striped">
          <colgroup>
            <col width="15%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{{ 'Contract' | translate }}</th>
              <td>{{ incident.contractName }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Category' | translate }}</th>
              <td>{{ incident.category.name }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Device type' | translate }}</th>
              <td>{{ incident.device?.deviceTypeName }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Location' | translate }}</th>
              <td>{{ incident.location }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Device' | translate }}</th>
              <td>{{ incident.device?.name }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Incident type' | translate }}</th>
              <td>{{ incident.incidentType?.name }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Source' | translate }}</th>
              <td>{{ incident.incidentSource?.name }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Scope' | translate }}</th>
              <td>{{ incident.scope | translate }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Description' | translate }}</th>
              <td>{{ incident.description }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Created date' | translate }}</th>
              <td>{{ incident.created | date: 'short' }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'Reporter' | translate }}</th>
              <td>{{ incident.reporter?.username }}</td>
            </tr>
            <tr class="last">
              <th scope="row">{{ 'Attachments' | translate }}</th>
              <td class="p-0">
                <app-attachments
                  (download)="downloadAttachment($event)"
                  [attachments]="incident.attachments"
                ></app-attachments>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <h3>{{ 'Thread' | translate }}</h3>
      <div class="row justify-content-center">
        <div class="col-3">
          <button class="btn btn-outline-success" (click)="createThread()">
            {{ 'New thread' | translate }}
          </button>
        </div>
        <div class="col-3">
          <button class="btn btn-outline-info" (click)="selectThread()">
            {{ 'Select existing' | translate }}
          </button>
        </div>
        <div class="col-3">
          <button *ngIf="canDeleteIncident()" class="btn btn-outline-danger" (click)="deleteIncident()">
            {{ 'Delete' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
