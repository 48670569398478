import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../../_helpers';


@Exclude()
export class MaintenanceCompany extends BaseEntity {

  @Expose()
  public name: String;
}
