import {classToPlain, ClassTransformOptions, Exclude, serialize} from 'class-transformer';

const serializationOptions: ClassTransformOptions = {
  strategy: 'excludeAll'
};

export abstract class EntitySerialization {
  public toJSON(): object {
    return classToPlain(this, serializationOptions);
  }

  public toString(): string {
    return serialize(this.toJSON(), serializationOptions);
  }
}
