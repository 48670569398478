import {RxStompConfig} from '@stomp/rx-stomp';
import {environment} from '../../../../environments/environment';

export function getStompConfig(token: string): RxStompConfig {
  return {
    brokerURL: environment.stompUrl,
    connectHeaders: {
      token,
    },

    // min interval between pings from server, final value will be max of this and server outgoing heartbeat
    heartbeatIncoming: 1000,
    heartbeatOutgoing: 0,
    reconnectDelay: 1000,
  };
}
