import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThreadService} from '../thread.service';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {SeverityEnum, ThreadStatusEnum} from '../../core/enum';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ThreadSearchInput} from './thread-search-input';
import {Pageable} from '../../pageable/pageable';
import {Observable, Subscription} from 'rxjs';
import {PageableParams} from '../../pageable/pageable.params';
import {SortDirection} from '../../pageable/sort.direction';
import {ThreadDto} from '../dto/thread.dto';
import {IncidentsStompService} from '../../core/services/stomp/incidents-stomp.service';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {ThemeNavbarService} from '../../layout/theme/theme-navbar.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-thread-list',
  templateUrl: './thread-list.component.html',
  styleUrls: ['./thread-list.component.scss']
})
export class ThreadListComponent implements OnInit, OnDestroy {
  public severity = SeverityEnum;
  public threadStatus = ThreadStatusEnum;

  private selectedContracts = [];
  private threadSearchInput = new ThreadSearchInput();
  private pageableParams = this.createPageableParams('threadCloseDueDate');
  private pageFetchSubscription: Subscription;
  searchForm: FormGroup;
  pageLoading = true;
  currentPage: Pageable<ThreadDto> = null;

  private deleteIncidentSubscription: Subscription;
  private formChangedSubscription: Subscription;
  private contractsSubscription: Subscription;

  constructor(
    fb: FormBuilder,
    private readonly threadService: ThreadService,
    private router: Router,
    private route: ActivatedRoute,
    private navbar: ThemeNavbarService,
    private authService: AuthService,
    private incidentsStompService: IncidentsStompService) {
    this.searchForm = fb.group(this.threadSearchInput);
  }

  public ngOnInit() {
    this.restoreFiltersAndPageFromUrl();
    this.onChanges();
    this.onIncidentDeleted();
    this.contractsSubscription = this.navbar.contractsSelection.subscribe(selected => this.onContractsChanged(selected));
  }

  public ngOnDestroy() {
    this.deleteIncidentSubscription.unsubscribe();
    this.formChangedSubscription.unsubscribe();

    if (this.contractsSubscription) {
      this.contractsSubscription.unsubscribe();
    }
  }

  private fetchCurrentPage() {
    this.pageLoading = true;
    this.currentPage = null;

    if (this.pageFetchSubscription) {
      this.pageFetchSubscription.unsubscribe();
    }

    this.pageFetchSubscription = this.fetchThreads().subscribe(page => {
      this.currentPage = page;
      this.pageLoading = false;
    });
  }

  private fetchThreads(): Observable<Pageable<ThreadDto>> {
    let search = ThreadSearchInput.convertObjectToParams(this.threadSearchInput);
    if (this.authService.userContracts) {
      search = search.append('contractId', '00000000-0000-0000-0000-000000000000'); // get empty list when none selected
    }
    this.selectedContracts.forEach(contract => search = search.append('contractId', contract.id));
    return this.threadService.searchBy(this.pageableParams, search);
  }

  private onChanges(): void {
    this.formChangedSubscription = this.searchForm.valueChanges
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(val => this.threadSearchInput = val),
        tap(() => this.saveFiltersSortingAndPageToUrl()),
        tap(() => this.resetToFirstPage())
      ).subscribe();
  }

  sortBy(sortField: string) {
    this.pageableParams.sortBy(sortField);
    this.resetToFirstPage();
    this.saveFiltersSortingAndPageToUrl();
  }

  private resetToFirstPage() {
    this.updatePageIndex(0);
  }

  onChangePageIndex(pageIdx: number) {
    if (this.pageableParams.pageIdx === pageIdx) {
      return;
    }
    this.updatePageIndex(pageIdx);
  }

  updatePageIndex(pageIdx: number) {
    this.pageableParams.pageIdx = pageIdx;
    this.saveFiltersSortingAndPageToUrl();
    this.fetchCurrentPage();
  }

  get displayLoader() {
    return this.pageLoading;
  }

  get displayPaginator() {
    const contentIsAvailable = this.currentPage !== null && this.currentPage.totalPages !== 0;
    return !this.pageLoading && contentIsAvailable;
  }

  private onIncidentDeleted() {
    this.deleteIncidentSubscription = this.incidentsStompService.deleteIncident$
      .pipe(tap(() => this.fetchCurrentPage()))
      .subscribe();
  }

  private onContractsChanged(selection: Object[]) {
    this.selectedContracts = selection;
    this.resetToFirstPage();
  }

  private saveFiltersSortingAndPageToUrl() {
    let params = {
      page: this.pageableParams.pageIdx + 1,
      sort: this.pageableParams.sortField + ',' + this.pageableParams.sortDirection
    };
    params = Object.assign(params, this.threadSearchInput);
    this.router.navigate([''], { queryParams: params, replaceUrl: true });
  }

  private restoreFiltersAndPageFromUrl() {
    const savedData = this.route.snapshot.queryParams;
    if (Object.keys(savedData).length) {
      try {
        for (const field of Object.keys(this.threadSearchInput)) {
          this.threadSearchInput[field] = savedData[field] === undefined ? null : savedData[field];
        }
        this.threadSearchInput.activeOnly = savedData.activeOnly === 'false' ? false : true;
        this.searchForm.setValue(this.threadSearchInput);
        this.pageableParams.pageIdx = savedData.page - 1;
      } catch (e) {
        console.error(e);
      }
    }
  }

  private createPageableParams(defaultSortField: string) {
    const savedData = this.route.snapshot.queryParamMap.get('sort');
    if (savedData) {
      const sort = savedData.split(',');
      if (sort.length === 2 && (sort[1] === SortDirection.ASC) || (sort[1] === SortDirection.DESC)) {
        return new PageableParams(sort[0], (sort[1] === SortDirection.ASC) ? SortDirection.ASC : SortDirection.DESC);
      } else {
        console.error('Unrecognized sorting order:', savedData);
      }
    }
    return new PageableParams(defaultSortField);
  }
}
