<div class="subnavbar-service-panel">
  <div class="today-container">
    <div class="today">
      <span>{{(this.now$| async) | date: 'shortTime'}}</span>
      <span class="divider">|</span>
      <span>{{(this.now$| async) | date: 'EEEE'|translate}}</span>
      <span class="divider">|</span>
      <span>{{(this.now$| async) | date: 'shortDate'}}</span>
    </div>
  </div>
  <div class="dropdown-container" [hidden]="broadcastService.contractsSelection.observers.length === 1 || contracts.length === 0">
    <ng-multiselect-dropdown
      [data]="contracts"
      [(ngModel)]="selectedContracts"
      (onSelect)="onSelectionChanged()"
      (onDeSelect)="onSelectionChanged()"
      (onSelectAll)="onToggleSelectAll($event)"
      (onDeSelectAll)="onToggleSelectAll($event)"
      [settings]="dropdownSettings"
      [placeholder]="'Contract' | translate">
    </ng-multiselect-dropdown>
  </div>
  <div class="subnav-container" [hidden]="true">
    <div class="subnav">
      <div class="nav-item active">
        <a href="#">{{'Device efficiency'|translate}}</a>
      </div>
      <div class="divider">|</div>
      <div class="nav-item">
        <a href="#">{{'Time quality'|translate}}</a>
      </div>
      <div class="divider">|</div>
      <div class="nav-item">
        <a href="#">{{'Profitability'|translate}}</a>
      </div>
    </div>
  </div>
</div>
