import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Pageable} from '../pageable/pageable';
import {IncidentEntity} from './entity';
import {PageableParams} from '../pageable/pageable.params';

@Injectable({
  providedIn: 'root'
})
export class IncidentProjectionService {

  constructor(protected http: HttpClient) {
  }

  public findAll(pageableParams: PageableParams, contractId: string): Observable<Pageable<IncidentEntity>> {
    const contractIds = contractId === '' ? '' : `&contracts=${contractId}`;
    return this.http.get<Pageable<IncidentEntity>>(`${environment.apiUrl}/incidents?projection=dto${contractIds}`, {
      params: pageableParams.asHttpParams()
    });
  }

}
