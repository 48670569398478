import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../../_helpers';

@Exclude()
export class DocumentEntity extends BaseEntity {

  @Expose()
  public name: string;

  @Expose()
  public mimetype: string;

  @Expose()
  public url: string;

  @Expose()
  public description: string;

  @Expose()
  public category: string;

  @Expose()
  public created: Date;
}
