import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {plainToClass} from 'class-transformer';
import {_t} from '../../_helpers';
import {ToastService} from '../../core/services';
import {IncidentEntity} from '../../incident/entity';
import {ThreadEntity} from '../entity';
import {ThreadService} from '../thread.service';

@Component({
  selector: 'app-thread-create',
  templateUrl: './thread-create.component.html',
  styleUrls: ['./thread-create.component.scss']
})
export class ThreadCreateComponent implements OnInit {

  public thread: ThreadEntity;
  public incident: IncidentEntity;
  public threadForm: FormGroup;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private toast: ToastService,
    private route: ActivatedRoute,
    private threadService: ThreadService
  ) {
    this.threadForm = fb.group({
      type: ['', Validators.required],
      description: ['']
    });
  }

  public ngOnInit(): void {
    this.incident = this.route.snapshot.data['incident'];
  }

  public async onSubmit() {
    this.thread = plainToClass<ThreadEntity, object>(ThreadEntity, this.threadForm.value);
    const thread = await this.threadService.save(this.thread).toPromise();
    await this.router.navigate(['/thread', thread.id]);
    this.toast.success(_t('Thread successfully created'), _t('Success!'));
  }

}
