<div class="row justify-content-center m-0">
  <div class="card col-8">
    <div class="card-header">
      <h3>{{ 'New incident' | translate }}</h3>
    </div>
    <div class="card-body">
      <div *ngIf="!incidentForm.valid && showFormErrors" class="errors-container">
        <div class="errors-list">
          <strong>{{ 'Error!' | translate }}</strong> {{ 'Please fill required fields: ' | translate }}
        </div>
        <ul class="errors-list">
          <li *ngFor="let controlName of errorControls" [ngSwitch]="controlName">
            <ng-container *ngSwitchCase="'contractId'">{{ 'Contract' | translate }}</ng-container>
            <ng-container *ngSwitchCase="'deviceId'">{{ 'Device' | translate }}</ng-container>
            <ng-container *ngSwitchCase="'incidentTypeId'">{{ 'Incident type' | translate }}</ng-container>
            <ng-container *ngSwitchCase="'incidentSourceId'">{{ 'Source' | translate }}</ng-container>
            <ng-container *ngSwitchCase="'scope'">{{ 'Scope' | translate }}</ng-container>
            <ng-container *ngSwitchCase="'description'">{{ 'Description' | translate }}</ng-container>
          </li>
        </ul>
      </div>
      <form id="incidentForm" [formGroup]="incidentForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-row">
          <div class="form-group col-md-12">
            <select
              class="form-control large"
              (change)="onContractChange($event.target.value)"
              formControlName="contractId"
            >
              <option value="" disabled selected hidden>{{ 'Contract' | translate }}</option>
              <option *ngFor="let contract of contracts" [value]="contract.id">
                {{ contract.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <app-autocomplete
              (value)="selectedDevice = $event"
              [options]="devices"
              [placeholder]="'Device' | translate"
            >
            </app-autocomplete>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <select class="form-control large" formControlName="incidentTypeId">
              <option value="" disabled hidden>{{ 'Incident type' | translate }}</option>
              <option *ngFor="let incidentType of incidentTypes" [value]="incidentType.id">
                {{ incidentType.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <select class="form-control large" formControlName="incidentSourceId">
              <option value="" disabled hidden>{{ 'Source' | translate }}</option>
              <option *ngFor="let incidentSource of incidentSources" [value]="incidentSource.id">
                {{ incidentSource.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <textarea
              class="form-control"
              rows="6"
              placeholder="{{ 'Description' | translate }}"
              formControlName="description"
            ></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="file-selector form-group col-md-12">
            <input
              type="file"
              (change)="addAttachments($event.target.files)"
              class="form-control large"
              multiple="multiple"
            />
            <label
              ><span>{{ 'Choose files' | translate }}</span></label
            >
          </div>
        </div>
        <div class="form-row">
          <table class="table table-bordered table-hover mb-0 files-table" *ngIf="attachments.length > 0">
            <thead>
              <tr>
                <th class="file-name text-left">{{ 'Attachments' | translate }}:</th>
                <th class="file-actions"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let attachment of attachments; let i = index">
                <td class="file-name" [title]="attachment.name">{{ attachment.name }}</td>
                <td class="file-actions">
                  <div class="action">
                    <span (click)="deleteAttachment(i)" class="delete-button"
                      ><fa-icon icon="trash"></fa-icon
                    ></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
    <div class="card-footer text-right">
      <button type="button" class="btn btn-outline-danger" [routerLink]="['/']">
        {{ 'Cancel' | translate }}
      </button>
      &nbsp;
      <button type="submit" form="incidentForm" class="btn btn-outline-success" [disabled]="creationInProgress">
        <div class="submit-button">
          <span>
            {{ 'Submit' | translate }}
          </span>
        </div>
      </button>
      <span class="loader-wrapper">
        <mat-spinner *ngIf="creationInProgress" diameter="20"></mat-spinner>
      </span>
    </div>
  </div>
</div>
