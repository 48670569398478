import { Exclude, Expose } from 'class-transformer';
import { BaseEntity } from '../../_helpers';

@Exclude()
export class AttachmentEntity extends BaseEntity {
  @Expose()
  public name: string;

  @Expose()
  public fileId: string;

  @Expose()
  public creationTimestamp: Date;
}
