import {Component, HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {shuffle} from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {Subject, timer} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {_t} from '../../_helpers';
import {ToastService} from '../../core/services';
import {AuthService} from '../auth.service';
import {dots} from './dots-cords';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public username: string;
  public password: string;
  public isError: boolean;
  public errorMsg: string;
  public dots: any[];
  public isLoginButtonDisabled = false;
  public cutDotsSvgHorizontally = false;
  public dotsSvgLeftMargin: number;
  private allDotsAnimated$ = new Subject();

  private readonly backgroundSizeRatio = 1.6;
  private readonly backgroundWidth = 1920;
  private readonly backgroundHeight = 1200;

  constructor(
    private readonly toast: ToastService,
    private authService: AuthService
  ) {
    this.dots = dots;
  }

  submit(): void {
    this.isLoginButtonDisabled = true;
    if (this.isError) {
      return this.doLogin();
    }
    this.animateDotsAndDoLogin();
  }

  private animateDotsAndDoLogin() {
    this.refreshDotsPosition();
    const randomizedDots = shuffle(this.dots);
    timer(100, 100)
      .pipe(
        takeUntil(this.allDotsAnimated$),
      ).subscribe(() => {
        if (randomizedDots.length > 0) {
          randomizedDots.pop().isVisible = true;
        } else {
          this.allDotsAnimated$.next();
          this.allDotsAnimated$.complete();
          this.doLogin();
        }
      }
    );
  }

  @HostListener('window:resize')
  onResize() {
    this.refreshDotsPosition();
  }

  private refreshDotsPosition() {
    const windowSizeRatio = window.innerWidth / window.innerHeight;
    this.cutDotsSvgHorizontally = windowSizeRatio < this.backgroundSizeRatio;

    if (this.cutDotsSvgHorizontally) {
      const backgroundPixelRatio = this.backgroundWidth * window.innerHeight;
      const backgroundCutPixels = ((backgroundPixelRatio / this.backgroundHeight) - window.innerWidth);
      this.dotsSvgLeftMargin = -backgroundCutPixels / 2;
    } else {
      this.dotsSvgLeftMargin = 0;
    }
  }

  private doLogin(): void {
    this.authService.login({username: this.username, password: this.password})
      .then(() => {
        this.isError = false;
        this.toast.success(_t('Logged in successfully'), _t('Success!'));
      })
      .catch((e) => {
        this.isError = true;
        this.isLoginButtonDisabled = false;
        this.errorMsg = e.message;
      });
  }
}
