import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { get, trimEnd } from 'lodash';
import { LOCAL_STORAGE, StorageService, StorageTranscoders } from 'ngx-webstorage-service';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthHttpClient } from './auth-http-client';
import { Credentials } from './credentials.interface';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserRole } from '../core/enum/user-role.enum';
import { TranslateService } from '@ngx-translate/core';

const JWT_STORAGE_KEY = 'jwtTokenSessionKey';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly apiUrl: URL;
  private jwtHelper = new JwtHelperService();
  private loggedOutSubject = new Subject<void>();
  loggedOut$ = this.loggedOutSubject.asObservable();

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private httpClient: AuthHttpClient,
    private router: Router,
    private translate: TranslateService
  ) {
    this.apiUrl = new URL(`${trimEnd(environment.apiUrl, '/')}/auth`);
  }

  get isLoggedIn(): Observable<boolean> {
    return of(this.storage.has(JWT_STORAGE_KEY));
  }

  get token(): string {
    const session = this.storage.get(JWT_STORAGE_KEY, StorageTranscoders.JSON);
    return get(session, 'token');
  }

  get loggedUsername(): string {
    const session = this.storage.get(JWT_STORAGE_KEY, StorageTranscoders.JSON);
    return get(session, 'username');
  }

  public async login(credentials: Credentials): Promise<boolean> {
    const data: any = await this.httpClient
      .post<any>(`${this.apiUrl}/login`, credentials)
      .toPromise()
      .catch(() => {
        throw new Error(this.translate.instant('Wrong username or password'));
      });
    const roles = this.jwtHelper.decodeToken(data.access_token).realm_access.roles;
    if (roles.includes(UserRole.CONTRACTED_OPERATOR) || roles.includes(UserRole.EXTERNAL_OPERATOR)) {
      this.storage.set(
        JWT_STORAGE_KEY,
        {
          username: credentials.username,
          token: data.access_token,
          refreshToken: data.refresh_token,
        },
        StorageTranscoders.JSON
      );
      return this.router.navigate(['/']);
    }
    throw new Error(this.translate.instant('You are not authorized for entering this application'));
  }

  public refreshToken(): Observable<any> {
    const session = this.storage.get(JWT_STORAGE_KEY, StorageTranscoders.JSON);
    const refreshToken = get(session, 'refreshToken');
    return this.httpClient
      .post<any>(`${this.apiUrl}/refreshToken`, {
        refreshToken,
      })
      .pipe(
        map((data) => {
          session.token = data.access_token;
          session.refreshToken = data.refresh_token;
          this.storage.set(JWT_STORAGE_KEY, session, StorageTranscoders.JSON);
        })
      );
  }

  public async logout(): Promise<boolean> {
    this.loggedOutSubject.next();
    this.storage.remove(JWT_STORAGE_KEY);
    return this.router.navigate(['/auth/login']);
  }

  public isContractedCompanyUser(): boolean {
    return !this.isExternalCompanyUser();
  }

  public isExternalCompanyUser(): boolean {
    const contractIds = this.jwtHelper.decodeToken(this.token).contractId;
    return !contractIds || contractIds.length === 0;
  }

  public isAdminUser(): boolean {
    const roles = this.jwtHelper.decodeToken(this.token).realm_access.roles;
    return roles.includes(UserRole.ADMIN);
  }

  public getUserId(): string {
    return this.jwtHelper.decodeToken(this.token).userId;
  }

  public getCompanyId(): string {
    return this.jwtHelper.decodeToken(this.token).companyId;
  }

  public get userContracts(): string[] {
    return this.jwtHelper.decodeToken(this.token).contractId;
  }
}
