import {Directive, ElementRef, OnDestroy} from '@angular/core';
import SimpleBar from 'simplebar';

@Directive({
  selector: '[themeSimplebar]'
})
export class SimplebarDirective implements OnDestroy {
  private simplebar: SimpleBar;

  constructor(el: ElementRef) {
    setTimeout(() => {
      this.simplebar = new SimpleBar(el.nativeElement);
    });
  }

  public ngOnDestroy(): void {
    if (this.simplebar) {
      this.simplebar.unMount();
    }
  }
}
