import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {_t} from '../../_helpers';
import {ToastService} from '../../core/services';
import {ThreadEntity, ThreadService} from '../../thread';
import {IncidentEntity} from '../entity';
import {IncidentThreadListModalComponent} from '../incident-thread-list-modal/incident-thread-list-modal.component';
import {AttachmentService} from 'src/app/core/services/attachment.service';
import {ConfirmService} from '../../core/services/confirm.service';
import {IncidentService} from '../incident.service';
import {IncidentOriginEnum} from '../../core/enum/incident-origin.enum';

@Component({
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
  styleUrls: ['./incident-detail.component.scss']
})
export class IncidentDetailComponent implements OnInit {

  public incident: IncidentEntity;

  constructor(
    private router: Router,
    private toast: ToastService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private threadService: ThreadService,
    private attachmentService: AttachmentService,
    private readonly confirmService: ConfirmService,
    private incidentService: IncidentService
  ) {
  }

  public ngOnInit(): void {
    this.incident = this.activatedRoute.snapshot.data['incident'];
  }

  public async createThread(): Promise<void> {
    const thread = await this.threadService.save({incidentId: this.incident.id} as any).toPromise();
    await this.router.navigate(['/thread', thread.id, 'metric']);
    this.toast.success(_t('New thread created successfully'), _t('Success!'));
  }

  public async selectThread(): Promise<void> {
    try {
      const injector: Injector = Injector.create({providers: [{provide: 'contractId', useValue: this.incident.contractId}]});
      const modalRef = this.modalService.open(IncidentThreadListModalComponent, {
        centered: true,
        windowClass: 'modal-xl',
        injector: injector
      });
      const thread: ThreadEntity = await modalRef.result;
      if (!thread) {
        return;
      }
      await this.threadService.addIncident(thread, this.incident).toPromise();
      await this.router.navigate(['/thread', thread.id, 'metric']);
      this.toast.success(_t('Incident successfully added to thread'), _t('Success!'));
    } catch (e) {
      console.error(e);
    }
  }

  public async deleteIncident(): Promise<void> {
    try {
      await this.confirmService.confirm('Delete incident', 'Are you sure you want to delete incident');
      try {
        await this.incidentService.delete(this.incident.id).toPromise();
        this.toast.success(_t('Incident successfully deleted'), _t('Success!'));
        await this.router.navigate(['/']);
      } catch {
        this.toast.error(_t('Could not delete incident'), _t('Error!'));
      }
    } catch (e) {
      // do nothing
    }
  }

  public canDeleteIncident(): boolean {
    return this.incident.incidentOrigin === IncidentOriginEnum.AUTOMATIC;
  }

  public downloadAttachment(attachmentId: string) {
    this.attachmentService.downloadIncidentAttachment(this.incident.id, attachmentId).subscribe(
      () => {},
      () => this.toast.error('Failed to download file', 'Error!')
    );
  }
}
