import {SortDirection} from './sort.direction';

const DEFAULT_PAGE_SIZE = 20;

export class PageableParams {
  pageIdx = 0;
  pageSize;
  sortField: string;
  sortDirection: SortDirection;

  constructor(sortField: string, sortDirection: SortDirection = SortDirection.ASC, pageSize = DEFAULT_PAGE_SIZE) {
    this.sortField = sortField;
    this.sortDirection = sortDirection;
    this.pageSize = pageSize;
  }

  sortBy(sortField: string) {
    if (this.sortField === sortField) {
      this.toggleSortDirection();
    } else {
      this.changeSortingField(sortField);
    }
  }

  asHttpParams(): {[param: string]: string} {
    return {
      page: `${this.pageIdx}`,
      size: `${this.pageSize}`,
      sort: this.getSortParam()
    };
  }

  private toggleSortDirection() {
    this.sortDirection = this.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
  }

  private changeSortingField(sortField: string) {
    this.sortField = sortField;
    this.sortDirection = SortDirection.ASC;
  }

  private getSortParam() {
    return `${this.sortField},${this.sortDirection}`;
  }
}
