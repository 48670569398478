export class SelectionItem {
  public id: string;
  public text: string;
  public isDisabled: boolean;

  constructor(id: string, text: string, isDisabled: boolean) {
    this.id = id;
    this.text = text;
    this.isDisabled = isDisabled;
  }
}
