import {HttpClient} from '@angular/common/http';
import {plainToClass} from 'class-transformer';
import {Observable} from 'rxjs';
import {defaultsDeep, get, trimEnd} from 'lodash';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {BaseEntity} from './base.entity';
import {ClassType} from 'class-transformer/ClassTransformer';

interface ApiPagable {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}

export interface ApiResponse<T> {
  [key: string]: any;
  page: ApiPagable;
}

export interface ApiArrayResponse<T> extends ApiResponse<T> {
  _embedded: {
    [p: string]: T[]
  };
}

export class BaseService<T extends BaseEntity> {

  protected http: HttpClient;

  protected readonly apiUrl: URL;

  constructor(protected readonly path: string, private readonly type: ClassType<T>) {
    this.apiUrl = new URL(`${trimEnd(environment.apiUrl, '/')}/${path}`);
  }

  public get url(): string {
    return this.apiUrl.toString();
  }

  public findById(id: string|number): Observable<T> {
    return this.http.get<ApiResponse<T>>(`${this.apiUrl}/${id}`)
      .pipe(map(data =>  plainToClass(this.type, data)));
  }

  public find(options?: any): Observable<T[]> {
    options = defaultsDeep({
      ...options
    }, {
      params: {
        size: Number.MAX_SAFE_INTEGER.toString()
      }
    });
    return this.http.get<ApiArrayResponse<T>>(`${this.apiUrl}`, options)
      .pipe(map(data =>  plainToClass<T, any>(this.type, get(data, `_embedded.${this.path.split('/').pop()}`))));
  }

  public save(model: T, options?: any): Observable<T> {
    const method = model.id ? 'put' : 'post';
    const url = model.id ? `${this.apiUrl}/${model.id}` : `${this.apiUrl}`;
    options = defaultsDeep({
      ...options,
    }, {
      body: model
    });
    return this.http.request<ApiResponse<T>>(method, url, options)
      .pipe(map(data =>  plainToClass<T, object>(this.type, data)));
  }

  public delete(id: string|number) {
    return this.http.delete<ApiResponse<T>>(`${this.apiUrl}/${id}`);
  }
}
