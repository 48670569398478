import {Component} from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'theme-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  constructor(private authService: AuthService) {}

  get isContractedUser() {
    return this.authService.isContractedCompanyUser();
  }
}
