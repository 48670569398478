import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {IncidentTypeEntity, LocationEntity} from '../../entity';
import {IncidentTypesDictionaryService} from '../../services';

@Component({
  selector: 'app-incident-types',
  template: `
    <form #f="ngForm" (submit)="onSelect()" novalidate>
      <div class="form-group">
        <select class="form-control" (change)="onSelect()" [compareWith]="myCompare" name="incident_type" [(ngModel)]="selectedType">
          <option [ngValue]="selectedType" disabled translate>Incident type</option>
          <option *ngFor="let type of types$ | async" [ngValue]="type" [selected]="type.id === selectedType?.id">
            {{type.name}}
          </option>
        </select>
      </div>
    </form>
  `
})
export class IncidentTypesComponent implements OnInit {

  public types$: Observable<IncidentTypeEntity[]>;
  public selectedType: IncidentTypeEntity;

  @Input()
  public selectedIncidentType: IncidentTypeEntity;

  @Output()
  public selected = new EventEmitter<IncidentTypeEntity>();

  constructor(private incidentTypesService: IncidentTypesDictionaryService) {
  }

  ngOnInit() {
    if (this.selectedIncidentType) {
      this.selectedType = this.selectedIncidentType;
    }
//    this.types$ = this.incidentTypesService.find();
  }

  public onSelect() {
    this.selected.emit(this.selectedType);
  }

  public myCompare(c1: LocationEntity, c2: LocationEntity) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
