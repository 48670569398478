<div class="container" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="end center">
  <button class="btn btn-outline-secondary" [disabled]="previousPageButtonDisabled" (click)="onPreviousPageButtonClick()">
    <span>{{ 'Previous' | translate }}</span>
  </button>
    <input type="number"
      [(ngModel)]="currentPageInput"
      (change)="onCurrentPageInputChange()"
    />
  <span> {{ 'outOf' | translate: allPagesTranslationParam }} </span>
  <button   class="btn btn-outline-secondary" [disabled]="nextPageButtonDisabled" (click)="onNextPageButtonClick()">
    <span>{{ 'Next' | translate }}</span>
  </button>
</div>
