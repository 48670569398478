import {Exclude, Expose, Type} from 'class-transformer';
import {BaseEntity} from '../../_helpers';
import {GeoPointEntity} from './geo-point.entity';
import {NameEntity} from './name.entity';

@Exclude()
export class LocationEntity extends BaseEntity {

  @Expose()
  @Type(() => NameEntity)
  public name: NameEntity;

  @Expose()
  @Type(() => GeoPointEntity)
  public geoPoint: GeoPointEntity;
}
