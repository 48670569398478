import {Exclude, Expose} from 'class-transformer';
import {BaseEntity, EntitySerialization} from '../../_helpers';
import {SeverityEnum} from '../enum';

@Exclude()
export class IncidentTypeEntity extends EntitySerialization {

  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  public severity: SeverityEnum;
}
