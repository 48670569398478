<div class="row justify-content-center m-0">
  <div class="card col-8">
    <div class="card-header">
      <ul class="nav nav-tabs nav-justified card-header-tabs">
        <li class="nav-item" *ngFor="let navItem of navItems">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="[navItem.name]">
            <span class="text-capitalize">{{navItem.name|translate}}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
