import {Component, Input, OnInit, Inject} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ThreadService} from '../../thread';
import {ThreadEntity} from '../../thread/entity';
import {ThreadStatusEnum} from 'src/app/core';
import {HttpParams} from '@angular/common/http';
import {Pageable} from '../../pageable/pageable';
import {ThreadDto} from '../../thread/dto/thread.dto';
import {PageableParams} from '../../pageable/pageable.params';
import {SortDirection} from '../../pageable/sort.direction';
import {ThreadSearchInput} from '../../thread/thread-list/thread-search-input';

@Component({
  selector: 'app-incident-thread-list-modal',
  templateUrl: './incident-thread-list-modal.component.html',
  styleUrls: ['./incident-thread-list-modal.component.scss']
})
export class IncidentThreadListModalComponent implements OnInit {

  currentPage: Pageable<ThreadDto>;
  selectedThread: ThreadDto;
  private pageableParams = new PageableParams('incidentCreationDate', SortDirection.DESC, 10000);

  constructor(
    @Inject('contractId') private contractId: string,
    public activeModal: NgbActiveModal,
    private readonly threadService: ThreadService
  ) { }

  public async ngOnInit() {
    this.currentPage = await this.threadService.searchBy(this.pageableParams, this.getNonClosedThreadsParams()).toPromise();
  }

  private getNonClosedThreadsParams() {
    const threadSearchInput = new ThreadSearchInput();
    threadSearchInput.contractIds = this.contractId;
    return threadSearchInput.convertObjectToParams();
  }
}
