import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FileDownloadService } from '../../core/services/file-download.service';

@Injectable()
export class AttachmentService {
  constructor(protected http: HttpClient, private fileDownloadService: FileDownloadService) {}

  public downloadIncidentAttachment(incidentId: string, fileId: string) {
    const url = `${environment.apiUrl}/incidents/${incidentId}/attachments/${fileId}`;
    return this.fileDownloadService.downloadFile(url);
  }

  public downloadThreadAttachment(threadId: string, fileId: string) {
    const url = `${environment.apiUrl}/threads/${threadId}/attachments/${fileId}`;
    return this.fileDownloadService.downloadFile(url);
  }
}
