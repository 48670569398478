import {Exclude, Expose, Type} from 'class-transformer';
import {BaseEntity} from '../../_helpers';
import {MaintenanceCompany} from '../../core/entity/maintenance.company';
import {ThreadAuditTypeEnum} from '../../core/enum/thread-audit-type.enum';
import {UserEntity} from '../../core/entity';

@Exclude()
export class ThreadAuditLogEntity extends BaseEntity {

  @Expose()
  public sourceCompany: MaintenanceCompany;

  @Expose()
  public targetCompany: MaintenanceCompany;

  @Expose()
  public user: UserEntity;

  @Expose()
  @Type(() => Date)
  public logDateTime: Date;

  @Expose()
  public description: string;

  @Expose()
  public auditType: ThreadAuditTypeEnum;

}
