import { Component, EventEmitter, Input, OnChanges, Output, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

export interface AutocompleteOption {
  value: any;
  name: string;
}

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnChanges, OnInit {
  @Input() options: AutocompleteOption[] = [];
  @Input() placeholder: string;
  @Input() initOption?: AutocompleteOption = null;
  @Output() value: EventEmitter<any> = new EventEmitter();

  autocompleteControl = new FormControl();

  filteredOptions: AutocompleteOption[] = [];

  ngOnInit() {
    this.autocompleteControl.valueChanges.subscribe(v => this.filter(v));
  }

  ngOnChanges() {
    if (!!this.initOption) {
      this.autocompleteControl.setValue(this.initOption);
    }

    if (this.options.length === 0) {
      this.autocompleteControl.disable();
    } else {
      this.autocompleteControl.enable();
    }

    this.filteredOptions = this.options;
  }

  displayOption(option?: AutocompleteOption) {
    return option ? option.name : '';
  }

  get disabled() {
    return this.options.length === 0;
  }

  get title() {
    const value = this.autocompleteControl.value;
    if (value && value.name) {
      return value.name;
    }

    return '';
  }

  private filter(filter: string | any) {
    if (typeof filter === 'string') {
      this.filterOptions(filter);
      this.value.emit();
    }
  }

  onValueChange(change: MatAutocompleteSelectedEvent) {
    this.value.emit(change.option.value);
  }

  private filterOptions(filter: string) {
    if (filter === '') {
      this.filteredOptions = this.options;
      return;
    }

    const filterValues = filter
      .toLocaleLowerCase()
      .split(' ')
      .map(p => p.trim());
    this.filteredOptions = this.options.filter(option =>
      this.containsEveryPart(option.name.toLowerCase(), filterValues)
    );
  }

  private containsEveryPart(name: string, parts: string[]) {
    return parts.every(p => name.includes(p));
  }
}
