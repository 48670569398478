<div class="dots-container">
  <div class="dots">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1200" preserveAspectRatio="xMidYMid"
         [class.svg-full-height]="cutDotsSvgHorizontally" [style.margin-left]="dotsSvgLeftMargin + 'px'">
      <g>
        <ng-container *ngFor="let dot of dots" >
          <ellipse *ngIf="dot.isVisible" class="dot" rx="4" ry="4" [attr.cx]="dot.x" [attr.cy]="dot.y" fill="#ff00ff"></ellipse>
        </ng-container>
      </g>
    </svg>
  </div>
</div>
<div class="login">
  <div class="login-container">
    <div class="brand">
      <span class="sign"></span>
    </div>

    <form class="login-form" (submit)="submit()" autocomplete="off" novalidate>
      <div class="container">
        <div class="row">
          <div *ngIf="isError" class="login-error">
            <strong>{{'Error!'|translate}}</strong>&nbsp;<span>{{ errorMsg }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="icon icon-user"></span>
              </div>
              <input [(ngModel)]="username" type="text" class="form-control" name="username"
                     placeholder="{{'Login'|translate}}">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="icon icon-password"></span>
              </div>
              <input [(ngModel)]="password" type="password" class="form-control" name="password"
                     placeholder="{{'Password'|translate}}">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="submit" style="display: none;">
          </div>
          <div class="col">
            <button class="btn btn-outline-primary" [disabled]="isLoginButtonDisabled">{{'Login'|translate}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
