import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DefaultNoComponentGlobalConfig, IndividualConfig, ToastrService} from 'ngx-toastr';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
  }

  public success(message?: string, title?: string, override?: Partial<IndividualConfig>, onTap?: () => void): void {
    this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.success, override, onTap);
  }

  public info(message?: string, title?: string, override?: Partial<IndividualConfig>, onTap?: () => void): void {
    this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.info, override, onTap);
  }

  public warning(message?: string, title?: string, override?: Partial<IndividualConfig>, onTap?: () => void, onClose?: () => void): void {
    this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.warning, override, onTap, onClose);
  }

  public error(message?: string, title?: string, override?: Partial<IndividualConfig>, onTap?: () => void): void {
    this.toast([message, title], DefaultNoComponentGlobalConfig.iconClasses.error, override, onTap);
  }

  private toast(keys: string[], type?: string, override?: Partial<IndividualConfig>, onTap?: () => void, onClose?: () => void): void {
    this.translate
      .get(keys)
      .pipe(map(translated => Object.values(translated)))
      .subscribe((translations: string[]) => {
        const activeToast = this.toastr.show(translations[0], translations[1], override, type);
        if (onTap) {
          activeToast.onTap.subscribe(onTap);
        }
        if (onClose) {
          activeToast.onHidden.subscribe(onClose);
        }
      });
  }
}
