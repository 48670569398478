import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ContractedUserGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(): boolean | UrlTree {
    const isContractedUser = this.authService.isContractedCompanyUser();
    if (!isContractedUser) {
      return this.router.parseUrl('/');
    }
    return isContractedUser;
  }
}
