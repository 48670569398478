import {Expose} from 'class-transformer';
import {EntitySerialization} from '../../_helpers';

export class NameEntity extends EntitySerialization {

  @Expose()
  public shortName: string;

  @Expose()
  public fullName: string;

  @Expose()
  public city: string;
}
