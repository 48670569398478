import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements AfterViewInit, OnInit {

  constructor(
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  ngAfterViewInit() {
    const spinner = this.document.getElementById('theme-global-spinner');
    spinner.style.display = 'none';
  }

  ngOnInit() {
  }
}
