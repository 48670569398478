import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {from, Observable, Subject, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {_t} from '../_helpers';
import {ToastService} from '../core/services';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private tokenRefreshedSource = new Subject();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private injector: Injector,
    private toast: ToastService,
    private translate: TranslateService,
    private authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.authService = this.injector.get(AuthService);

    // Handle request
    request = this.addAuthHeader(request);

    // Handle response
    return next.handle(request).pipe(catchError(error => {

      if (error.status === 401) {
        return this.refreshToken().pipe(
          switchMap(() => {
            request = this.addAuthHeader(request);
            return next.handle(request);
          }),
          catchError(() => {
            this.toast.success(_t('Session time-out'), _t('Logout!'));
            return from(this.authService.logout());
          })
        );
      }

      return throwError(error);
    }));
  }


  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.token;
    if (token) {
      return request.clone({
        setHeaders: {
          'Authorization':  `Bearer ${token}`
        }
      });
    }
    return request;
  }

  private refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refreshToken()
        .pipe(
          map(() => {
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next();
          })
        );
    }
  }
}
