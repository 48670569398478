import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {_t} from '../../_helpers';
import {DictionaryEntity} from '../../core/entity';
import {ScopeEnum} from '../../core/enum';
import {ApiConfigService, ToastService} from '../../core/services';
import {IncidentEntity} from '../entity';
import {IncidentService} from '../incident.service';

@Component({
  selector: 'app-incident-create',
  templateUrl: './incident-edit.component.html',
  styleUrls: ['./incident-edit.component.scss']
})
export class IncidentEditComponent implements OnInit {

  public incident: IncidentEntity;
  public incidentForm: FormGroup;
  public selectedLocation = new DictionaryEntity();

  public incidentScope = ScopeEnum;

  constructor(
    fb: FormBuilder,
    public apiConfig: ApiConfigService,
    private router: Router,
    private toast: ToastService,
    private activatedRoute: ActivatedRoute,
    private incidentService: IncidentService
  ) {
    this.incidentForm = fb.group({
      deviceId: ['', Validators.required],
      incidentTypeId: ['', Validators.required],
      description: [''],
      scope: [''],
      source: ['']
    });
  }

  public ngOnInit(): void {
    this.incident = this.activatedRoute.snapshot.data['incident'];
    this.apiConfig.locations$.subscribe(locations => {
      const location = locations.find(l => l.id === this.incident.device.locationId);
      if (location) {
        this.selectedLocation = location;
      }
      this.incidentForm.patchValue({
        deviceId: this.incident.device.id,
        incidentTypeId: this.incident.incidentType.id,
        description: this.incident.description,
        scope: this.incident.scope,
      });
    });
  }

  public async onSubmit(): Promise<void> {
    const incident = await this.incidentService.save(this.incidentForm.value).toPromise();
    await this.router.navigate(['/incident', incident.id]);
    this.toast.success(_t('Incident successfully modified'), _t('Success!'));
  }
}
