import {Injectable} from '@angular/core';
import {ApiConfigService} from './api-config.service';
import {CarService} from './car.service';
import {WorkerService} from './worker.service';
import {ContractDictionaryService} from './contract-dictionary.service';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
    private readonly apiConfigService: ApiConfigService,
    private readonly carService: CarService,
    private readonly workerService: WorkerService,
    private readonly contractDictionaryService: ContractDictionaryService,
  ) {
  }

  public onAppInit(): void {
    this.initCars();
    this.initWorkers();
    this.initContracts();
  }

  private initCars(): void {
    this.apiConfigService.cars$ = this.carService.find();
  }

  private initWorkers(): void {
    this.apiConfigService.workers$ = this.workerService.find();
  }

  private initContracts(): void {
    this.apiConfigService.contracts$ = this.contractDictionaryService.find();
  }
}
