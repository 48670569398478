<div class="modal-header">
  <h4 class="modal-title">{{title|translate}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span>{{description|translate}}</span>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">
    {{dismissButtonText | translate}}
  </button>
  &nbsp;
  <button type="button" class="btn btn-outline-success" (click)="activeModal.close()">
    {{submitButtonText | translate}}
  </button>
</div>
