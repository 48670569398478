import {Expose} from 'class-transformer';
import {EntitySerialization} from '../../_helpers';

export class GeoPointEntity extends EntitySerialization {

  @Expose()
  public latitude: number;

  @Expose()
  public longitude: number;
}
