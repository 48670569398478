<div class="row mr-0 ml-0">
  <div class="col-12 p-0">
    <div class="card" [hidden]="true">
      <div class="card-header">
        <h3>{{'Last refresh'|translate}}</h3>
      </div>
      <div class="card-body">
        <form class="form-inline filters">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-6">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="{{'Date'|translate}}" [value]="lastRefresh | date:'short'">
                </div>
              </div>
              <div class="col-4 text-right align-self-center">
                <button type="button" class="btn btn-outline-danger" (click)="doRefresh()">{{'Refresh'|translate}}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-6">
              <h3>{{'Awaiting incidents'|translate}}</h3>
            </div>
            <div class="col-6 text-right">
              <button type="button" routerLink="/incident/create" class="btn btn-outline-success">
                {{'Add incident'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="inset grow vscroll" themeSimplebar>
          <table class="table table-bordered table-hover mb-0">
            <thead>
            <tr class="filters" [hidden]="true">
              <th class="filter">
                <div class="form-group">
                  <input type="datetime-local" class="form-control" placeholder="{{'Date'|translate}}"
                         (change)="doFilter({created: $event.target.value})">
                </div>
              </th>
              <th class="filter">
                <app-incident-types (selected)="doFilter({incidentTypeId: $event.id})"></app-incident-types>
              </th>
              <th class="filter">
                <app-locations (selected)="doFilter({locationId: $event.id})"></app-locations>
              </th>
            </tr>
            <tr>
              <th [sortableColumn]="sortableIncidents" sortableColumnField="created">{{'Date'|translate}}</th>
              <th>{{'Severity'|translate}}</th>
              <th>{{'Localization'|translate}}</th>
            </tr>
            </thead>
            <tbody *ngIf="sortableIncidents.data?.length; else loading">
            <tr *ngFor="let incident of sortableIncidents.data" [routerLink]="['/incident', incident.id]" [class.reaction-deadline-warning]="incident.isReactionDateEndangered()" class="incident-details">
              <td>{{incident.created | date: 'short'}}</td>
              <td>
                <span class="severity" [ngClass]="incident.incidentType.severity.toLowerCase()"></span>
                {{incident.incidentType?.name}}
              </td>
              <td>{{incident.cityLocation}}</td>
            </tr>
            </tbody>
            <ng-template #loading>
              <tbody>
              <tr>
                <td colspan="3" class="text-center">{{'There are no incidents'|translate}}</td>
              </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
