import {_t} from '../../_helpers';

export class ThreadDetailService {
  static readonly SubComponents = [
    {
      name: _t('metric')
    }, {
      name: _t('incidents')
    }, {
      name: _t('activities')
    }, {
      name: _t('history')
    }, {
      name: _t('documentation')
    }, {
      name: _t('archives')
    }, {
      name: _t('notes')
    }
  ];
}
