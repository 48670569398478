<div class="row justify-content-center m-0">
  <div class="col-8">

    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12">
            <h3>{{'Contract'|translate}}</h3>
            <div class="form-group">
              <select class="form-control large" [(ngModel)]="contractId" (ngModelChange)="onContractSelect()" name="contract-id">
                <option value="" disabled selected hidden>{{'Contract' | translate}}</option>
                <option value="">{{'All contracts' | translate}}</option>
                <option *ngFor="let contract of contracts$ | async" [value]="contract.id">
                  {{contract.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h3>{{'Awaiting incidents'|translate}}</h3>
      </div>
      <div class="card-body">

        <div class="row">
          <div class="col-12">
            <div class="inset grow vscroll" themeSimplebar>
              <table class="table table-bordered table-hover mb-0">
                <thead>
                <tr>
                  <th>{{'Date'|translate}} <a (click)="sortBy('incidentCreationDate')"><img src="./assets/images/sort.png" alt=""></a></th>
                  <th>{{'Contract'|translate}} <a (click)="sortBy('contractName')"><img src="./assets/images/sort.png" alt=""></a></th>
                  <th>{{'Device'|translate}} <a (click)="sortBy('deviceName')"><img src="./assets/images/sort.png" alt=""></a></th>
                  <th>{{'Severity'|translate}} <a (click)="sortBy('incidentName')"><img src="./assets/images/sort.png" alt=""></a></th>
                  <th>{{'Source'|translate}} <a (click)="sortBy('incidentSource')"><img src="./assets/images/sort.png" alt=""></a></th>
                  <th>{{'Description'|translate}} <a (click)="sortBy('description')"><img src="./assets/images/sort.png" alt=""></a></th>
                  <th>{{'Due date'|translate}} <a (click)="sortBy('reactionDueDate')"><img src="./assets/images/sort.png" alt=""></a></th>
                </tr>
                </thead>
                <tbody *ngIf="currentPage?.content?.length; else loading">
                <tr *ngFor="let incident of currentPage.content" [routerLink]="['/incident/', incident.id]">
                  <td>{{incident.incidentCreationDate | date: 'short'}}</td>
                  <td>{{incident.contractName}}</td>
                  <td>{{incident.deviceName}}</td>
                  <td>
                    <span class="severity" [ngClass]="incident.severity.toLowerCase()"></span>
                    {{incident.incidentName}}
                  </td>
                  <td>{{incident.incidentSource}}</td>
                  <td>{{incident.description}}</td>
                  <td>{{incident.reactionDueDate | date: 'short'}}</td>
                </tr>
                </tbody>
                <ng-template #loading>
                  <tbody>
                  <tr>
                    <td colspan="10" class="text-center">{{'There are no incidents'|translate}}</td>
                  </tr>
                  </tbody>
                </ng-template>
              </table>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <app-paginator
            [pagesCount]="currentPage?.totalPages"
            [pageIndex]="pageableParams.pageIdx"
            (pageIndexChange)="changePageIndex($event)"
          ></app-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
