import {Injectable} from '@angular/core';
import {BaseService} from '../../_helpers';
import {HttpClient} from '@angular/common/http';
import {DeviceEntity} from '../../incident/entity';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceDictionaryService extends BaseService<DeviceEntity> {
  constructor(protected http: HttpClient) {
    super('config/devices/idAndNames', DeviceEntity);
  }

  public findById(id: string | number): Observable<DeviceEntity> {
    return this.http.get<DeviceEntity>(`api/config/devices/${id}/flat`);
  }

}
