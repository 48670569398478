import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ThreadEntity} from '../entity';
import {ThreadDetailService} from './thread-detail.service';

@Component({
  selector: 'app-thread-detail',
  templateUrl: './thread-detail.component.html',
  styleUrls: ['./thread-detail.component.scss']
})
export class ThreadDetailComponent implements OnInit {

  public navItems = ThreadDetailService.SubComponents;
  public thread: ThreadEntity;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  public async ngOnInit(): Promise<void> {
    this.thread = this.activatedRoute.snapshot.data['thread'];
  }
}
