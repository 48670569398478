import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() pagesCount: number = null;
  @Input()
  set pageIndex(value: number) {
    if (this.pageIdx !== value) {
      this.pageIdx = value;
      this.onCurrentPageIdxUpdate();
    }
  }
  @Output() pageIndexChange = new EventEmitter<number>();

  private pageIdx = 0;

  currentPageInput = 1;
  currentPageInputError = false;

  onPreviousPageButtonClick() {
    this.pageIdx--;
    this.onCurrentPageIdxUpdate();
  }

  onNextPageButtonClick() {
    this.pageIdx++;
    this.onCurrentPageIdxUpdate();
  }

  onCurrentPageIdxUpdate() {
    this.currentPageInput = this.pageIdx + 1;
    this.currentPageInputError = false;
    this.pageIndexChange.emit(this.pageIdx);
  }

  onCurrentPageInputChange() {
    const value = this.currentPageInput;
    if (!value || Number.isNaN(value) || value < 1 || value > this.pagesCount) {
      this.currentPageInputError = true;
      return;
    }

    const valueAsIdx = value - 1;
    this.currentPageInputError = false;

    if (this.pageIdx === valueAsIdx) {
      return;
    }
    this.pageIdx = valueAsIdx;
    this.onCurrentPageIdxUpdate();
  }

  get previousPageButtonDisabled() {
    return this.pageIdx <= 0;
  }

  get nextPageButtonDisabled() {
    return this.pageIdx >= this.pagesCount - 1;
  }

  get allPagesTranslationParam() {
    return {
      value: this.pagesCount,
    };
  }
}
