import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentEntity } from '../../entity/attachment.entity';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent {
  @Input() attachments: AttachmentEntity[];
  @Output() download = new EventEmitter<string>();

  constructor() {}

  public onDownload(attachmentId: string) {
    return this.download.emit(attachmentId);
  }
}
