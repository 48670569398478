import {Exclude, Expose, Type} from 'class-transformer';
import {BaseEntity} from '../../_helpers';
import {ActivityType} from '../enum/activity-type.enum';
import {WorkerEntity} from './worker.entity';
import {CarEntity} from './car.entity';
import {UserEntity} from './user.entity';

@Exclude()
export class ActivityEntity extends BaseEntity {

  @Expose()
  @Type(() => Date)
  public createdDateTime: Date;

  @Expose()
  public configActivityId: string;

  @Expose()
  public name: string;

  @Expose()
  public author: UserEntity;

  @Expose()
  public description: string;

  @Expose()
  public internal: boolean;

  @Expose()
  public activityType: ActivityType;

  @Expose()
  @Type(() => Date)
  public activityDateTime: Date;

  @Expose()
  @Type(() => WorkerEntity)
  public workers: WorkerEntity[];

  @Expose()
  @Type(() => CarEntity)
  public cars: CarEntity[];

  @Expose()
  public companyId: string;
}
