import {Pipe, PipeTransform} from '@angular/core';
import {Duration} from 'luxon';
import {timer} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({name: 'timeLeft'})
export class TimeLeftPipe implements PipeTransform {
  private formater = ['d', 'h', `'`, `"`];

  getDisplayFormat(duration: Duration): string {
    const MINUS_ONE_DAY = -86400000;
    return (duration.milliseconds < 0 && duration.milliseconds > MINUS_ONE_DAY) ? `-dd hh mm ss` : `dd hh mm ss`;
  }

  transform(value: Date, args: string[]): any {
    /** @type Duration */
    return timer(0, 1000).pipe(map(() => {
      const duration = Duration.fromMillis(value.getTime() - Date.now());
      const displayFormat: string = this.getDisplayFormat(duration);
      return duration
        .toFormat(displayFormat)
        .split(' ')
        .map((k, i) => i !== 0 ? k.replace('-', '') : k)
        .map((k, i) => k + this.formater[i])
        .join(' ');
    }));
  }


}
