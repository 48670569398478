import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SortableSource } from './sortable-source';

@Component({
  // tslint:disable-next-line
  selector: '[sortableColumn]',
  styleUrls: ['./sortable-column.component.scss'],
  templateUrl: './sortable-column.component.html'
})
export class SortableColumnComponent<T> {
  @Input()
  public sortableColumn: SortableSource<T>;
  @Input()
  public sortableColumnField: keyof T;

  private sortDirection: 'asc' | 'desc' = 'desc';

  @HostListener('click')
  sort() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortableColumn.sort(this.sortableColumnField, this.sortDirection);
  }
}
