import { Exclude, Expose } from 'class-transformer';
import { BaseEntity } from '../../_helpers';

@Exclude()
export class CarEntity extends BaseEntity {
  @Expose()
  public mark: string;

  @Expose()
  public model: string;

  @Expose()
  public costPerKm: number;

  @Expose()
  public registrationNumber: string;

  public checked?: boolean; // only for activity-create.component.html view

  get name() {
    return this.mark + ' ' + this.model + ' ' + (this.registrationNumber || '');
  }
}
