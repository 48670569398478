export const dots = [
  {x: 1616, y: 182, isVisible: false},
  {x: 1366, y: 336, isVisible: false},
  {x: 1582, y: 368, isVisible: false},
  {x: 1252, y: 407, isVisible: false},
  {x: 1333, y: 427, isVisible: false},
  {x: 1162, y: 459, isVisible: false},
  {x: 1764, y: 462, isVisible: false},
  {x: 1200, y: 526, isVisible: false},
  {x: 1402, y: 546, isVisible: false},
  {x: 982, y: 597, isVisible: false},
  {x: 1223, y: 596, isVisible: false},
  {x: 1125, y: 615, isVisible: false},
  {x: 1726, y: 620, isVisible: false},
  {x: 1008, y: 656, isVisible: false},
  {x: 1202, y: 657, isVisible: false},
  {x: 1215, y: 685, isVisible: false},
  {x: 1290, y: 707, isVisible: false},
  {x: 1590, y: 708, isVisible: false},
  {x: 1755, y: 734, isVisible: false},
  {x: 1393, y: 795, isVisible: false},
  {x: 1887, y: 802, isVisible: false},
  {x: 1547, y: 825, isVisible: false},
  {x: 1128, y: 835, isVisible: false},
  {x: 1005, y: 858, isVisible: false},
  {x: 1102, y: 876, isVisible: false},
  {x: 1683, y: 888, isVisible: false},
  {x: 1245, y: 902, isVisible: false},
  {x: 1049, y: 929, isVisible: false},
  {x: 1318, y: 924, isVisible: false},
  {x: 993, y: 957, isVisible: false},
  {x: 1228, y: 975, isVisible: false},
  {x: 1418, y: 974, isVisible: false},
  {x: 1543, y: 972, isVisible: false},
  {x: 1701, y: 961, isVisible: false},
  {x: 1329, y: 1056, isVisible: false},
  {x: 1654, y: 1044, isVisible: false},
  {x: 1160, y: 1068, isVisible: false},
  {x: 1080, y: 1179, isVisible: false},
  {x: 677, y: 860, isVisible: false},
  {x: 835, y: 983, isVisible: false},
  {x: 855, y: 1133, isVisible: false}
];
