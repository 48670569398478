import {Exclude, Expose, Type} from 'class-transformer';
import {DueDateMode} from '../enum/due-date-mode.enum';
import {BaseEntity} from '../../_helpers';

@Exclude()
export class DueDate extends BaseEntity {
  @Expose()
  @Type(() => Date)
  public dueDateTime: Date;

  @Expose()
  public dueDateMode: DueDateMode;
}
