import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../../_helpers';
import {DictionaryEntity} from '../entity';

@Injectable({
  providedIn: 'root'
})
export class IncidentTypesDictionaryService extends BaseService<DictionaryEntity> {
  constructor(protected http: HttpClient) {
    super('config/incidentTypes/idAndNames', DictionaryEntity);
  }
}
