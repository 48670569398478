import {SeverityEnum, ThreadStatusEnum} from '../../core/enum';
import {Type} from 'class-transformer';

export class ThreadSource {
  id: string;
  name: string;
  shortcut: string;
}

export class ThreadDto {
  id: string;
  threadNumber: string;
  @Type(() => Date)
  incidentCreationDate: Date;
  @Type(() => Date)
  threadCreationDate: Date;
  @Type(() => Date)
  reactionDueDate: Date;
  @Type(() => Date)
  threadCloseDate: Date;
  @Type(() => Date)
  threadCloseDueDate: Date;
  @Type(() => Date)
  lastDeadlineAcknowledgementDateTime: Date;
  severity: SeverityEnum;
  @Type(() => ThreadSource)
  source?: ThreadSource;
  error?: boolean;
  incidentName: string;
  fixTimeDuration: string;
  reactionTimeDuration: string;
  threadStatus: ThreadStatusEnum;
  deviceName?: string;
  deviceId?: string;
  incidentSourceName?: string;
  description: string;
  locationName: string;
  city: string;
  deviceTypeName: string;
  maintenanceCompanyName: string;

  get isActive() {
    return this.threadStatus !== ThreadStatusEnum.CLOSED;
  }

  public isSlaEndangered(): boolean {
    const timeLeft = this.threadCloseDueDate.getTime() - Date.now();
    const timeBetweenDeadlineAndLastAck = this.threadCloseDueDate.getTime() - this.lastDeadlineAcknowledgementDateTime.getTime();

    return timeLeft < timeBetweenDeadlineAndLastAck / 2;
  }
}
