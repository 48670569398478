<div class="autocomplete" (click)="trigger.openPanel()">
  <input
    matInput
    #trigger="matAutocompleteTrigger"
    [formControl]="autocompleteControl"
    [title]="title"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
  />
  <div class="mat-select-arrow-wrapper">
    <div class="mat-select-arrow"></div>
  </div>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onValueChange($event)" [displayWith]="displayOption">
    <mat-option *ngFor="let option of filteredOptions" [value]="option.value" [title]="option.name">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</div>
