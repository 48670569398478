import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ThemeModule } from '../layout/theme';
import { ConfirmModalComponent, IncidentTypesComponent, LocationsComponent } from './components';
import { RequestCacheService } from './services/request-cache.service';
import { SortableColumnComponent } from './components/table/sortable-column.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DurationPipe } from './pipes/DurationPipe';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFileDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AttachmentService } from './services/attachment.service';
import { AttachmentsComponent } from './components/attachments/attachments.component';

const PROVIDERS = [
  AttachmentService,
  RequestCacheService /* ,

  on purpose, for some reason, this stuff is not working, need to investigate more time
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true
  } */,
];

const COMPONENTS = [
  AutocompleteComponent,
  LocationsComponent,
  IncidentTypesComponent,
  ConfirmModalComponent,
  SortableColumnComponent,
  LoaderComponent,
  DurationPipe,
  PaginatorComponent,
  AttachmentsComponent,
];

@NgModule({
  imports: [
    ThemeModule,
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FontAwesomeModule,
  ],
  declarations: [...COMPONENTS],
  providers: [...PROVIDERS],
  exports: [...COMPONENTS],
  entryComponents: [ConfirmModalComponent],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
  constructor(library: FaIconLibrary) {
    library.addIcons(faTrash, faFileDownload);
  }
}
